import {useState, ChangeEvent} from 'react'
import * as React from 'react'
import {Step3Props} from '../../interfaces/clientsTypes'
import {Step3Errors} from '../../interfaces/clientsTypes'
import {useIntl} from 'react-intl'

function Step3({onNext, handlePrev, prevData, setStep3Data}: Step3Props) {
  const [brandName, setBrandName] = useState(prevData?.brandName || '')
  const [productName, setProductName] = useState(prevData?.productName || '')
  const [productPrice, setProductPrice] = useState(prevData?.productPrice || '')
  const [productMailsUsers, setProductMailsUsers] = useState(prevData?.productMailsUsers || [])
  const [productType, setProductType] = useState(prevData?.productType || '')
  const [productLegacy, setProductLegacy] = useState(prevData?.productLegacy || '')
  const [productCountry, setProductCountry] = useState(prevData?.productCountry || '')
  const [productState, setProductState] = useState(prevData?.productState || '')
  const [productCity, setProductCity] = useState(prevData?.productCity || '')
  const [productZone, setProductZone] = useState(prevData?.productZone || '')
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const [errors, setErrors] = useState<Step3Errors>({
    brandName: '',
    productName: '',
    productPrice: '',
    productMailsUsers: '',
    productType: '',
    productCountry: '',
    productState: '',
    productCity: '',
    productZone: '',
  })

  console.log(productType)

  const validateInputs = () => {
    const newErrors: Step3Errors = {
      brandName: !brandName ? intl.formatMessage({id: 'CLIENTS.BRAND_REQUIRED'}) : '',
      productName: !productName ? intl.formatMessage({id: 'CLIENTS.PRODUCT_REQUIRED'}) : '',
      productPrice: !productPrice ? intl.formatMessage({id: 'CLIENTS.PRICE_REQUIRED'}) : '',
      productMailsUsers:
        productMailsUsers.length === 1 && productMailsUsers[0] === ''
          ? intl.formatMessage({id: 'CLIENTS.ENTER_EMAIL'})
          : productMailsUsers.length === 0
            ? intl.formatMessage({id: 'CLIENTS.AT_LEAST_ONE_EMAIL'})
            : '',
      productType:
        productType === '0' || !productType
          ? intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_REQUIRED'})
          : '',
      productCountry: !productCountry ? intl.formatMessage({id: 'CLIENTS.COUNTRY_REQUIRED'}) : '',
      productState: !productState ? intl.formatMessage({id: 'CLIENTS.STATE_REQUIRED'}) : '',
      productCity: !productCity ? intl.formatMessage({id: 'CLIENTS.CITY_REQUIRED'}) : '',
      productZone: !productZone ? intl.formatMessage({id: 'CLIENTS.MUNICIPALITY_REQUIRED'}) : '',
    }

    // Aquí actualizamos el estado errors con los nuevos mensajes de error
    setErrors(newErrors)

    return Object.values(newErrors).every((error) => error === '')
  }

  console.log(errors)
  console.log(productMailsUsers)

  const handleNext = () => {
    if (validateInputs()) {
      onNext({
        brandName,
        productName,
        productPrice: typeof productPrice === 'number' ? productPrice : 0,
        productMailsUsers,
        productType,
        productLegacy,
        productCountry,
        productState,
        productCity,
        productZone,
      })

      setStep3Data({
        brandName,
        productName,
        productPrice: typeof productPrice === 'number' ? productPrice : 0,
        productMailsUsers,
        productType,
        productLegacy,
        productCountry,
        productState,
        productCity,
        productZone,
      })
    }
  }

  const handleMailsChange = (e: ChangeEvent<HTMLInputElement>) => {
    // Dividir la cadena de correos electrónicos por comas y actualizar el estado
    const mailsArray = e.target.value.split(',').map((email) => email.trim())
    setProductMailsUsers(mailsArray)
  }

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.trim()
    setProductPrice(newValue === '' ? '' : Number(newValue))
  }

  return (
    <div>
      <h3>{intl.formatMessage({id: 'CLIENTS.BRAND'})}</h3>
      <span className='text-muted'>{intl.formatMessage({id: 'CLIENTS.CREATE_BRAND_NAME'})}</span>
      <form>
        <div className='col-6 me-2 mb-5 mt-3'>
          <input
            type='text'
            id='brandName'
            placeholder={intl.formatMessage({id: 'CLIENTS.ENTER_BRAND_NAME'})}
            className='form-control'
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
          />
          <div className='text-danger mb-6'>{errors.brandName}</div>
        </div>
        <h3>{intl.formatMessage({id: 'CLIENTS.PRODUCT'})}</h3>
        <span className='text-muted'>{intl.formatMessage({id: 'CLIENTS.CREATE_PRODUCT'})}</span>

        <div className='d-flex mt-4'>
          <div className='col-6 me-2'>
            <input
              type='text'
              id='productName'
              placeholder={intl.formatMessage({id: 'CLIENTS.ENTER_PRODUCT_NAME'})}
              className='form-control'
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
            <div className='text-danger mb-6'>{errors.productName}</div>
          </div>

          <div className='col-6'>
            <input
              type='number'
              id='productPrice'
              placeholder={intl.formatMessage({id: 'CLIENTS.PRODUCT_PRICE'})}
              className='form-control'
              value={productPrice}
              onChange={handlePriceChange}
            />
            <div className='text-danger mb-6'>{errors.productPrice}</div>
          </div>
        </div>

        <div>
          <input
            type='text'
            id='productMailsUsers'
            placeholder={intl.formatMessage({id: 'CLIENTS.ENTER_NOTIFICATION_EMAILS'})}
            className='form-control mt-4'
            value={productMailsUsers.join(', ')}
            onChange={handleMailsChange}
          />
          <div className='text-danger mb-6'>{errors.productMailsUsers}</div>
        </div>

        <div className='d-flex mt-4'>
          <div className='col-6 me-2'>
            <select
              className='form-select'
              id='productType'
              value={productType}
              onChange={(e) => setProductType(e.target.value)}
            >
              <option value='0'>{intl.formatMessage({id: 'CLIENTS.SELECT_PRODUCT_TYPE'})}</option>
              <option value='Consultorios'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_CLINICS'})}
              </option>
              <option value='Desarrollo Inmobiliario'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_REAL_ESTATE_DEVELOPMENT'})}
              </option>
              <option value='App Digital'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_DIGITAL_APP'})}
              </option>
              <option value='Empresa'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_COMPANY'})}
              </option>
              <option value='Corporation'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_CORPORATION'})}
              </option>
              <option value='Corretaje Inmobiliario'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_REAL_ESTATE_BROKERAGE'})}
              </option>
              <option value='Agencia de Autos'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_CAR_DEALERSHIP'})}
              </option>
              <option value='Casas y Departamentos'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_HOUSES_AND_APARTMENTS'})}
              </option>
              <option value='Asesoría Legal'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_LEGAL_ADVICE'})}
              </option>
              <option value='Agencia'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_AGENCY'})}
              </option>
              <option value='Corporativo'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_CORPORATE'})}
              </option>
              <option value='Oficinas'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_OFFICES'})}
              </option>
              <option value='Casas'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_HOUSES'})}
              </option>
              <option value='Departamentos'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_APARTMENTS'})}
              </option>
              <option value='Terrenos'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_LANDS'})}
              </option>
              <option value='Procesador de Pagos'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_PAYMENT_PROCESSOR'})}
              </option>
              <option value='Gimnasios'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_GYMS'})}
              </option>
              <option value='Fintech'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_FINTECH'})}
              </option>
              <option value='Lojas'>
                {intl.formatMessage({id: 'CLIENTS.PRODUCT_TYPE_STORES'})}
              </option>
            </select>
            <div className='text-danger mb-6'>{errors.productType}</div>
          </div>

          <div className='col-6'>
            <input
              type='text'
              id='productLegacy'
              placeholder={intl.formatMessage({id: 'CLIENTS.PRIVACY_NOTICE'})}
              className='form-control'
              value={productLegacy}
              onChange={(e) => setProductLegacy(e.target.value)}
            />
          </div>
        </div>

        <div className='d-flex mt-4'>
          <div className='col-6 me-2'>
            <input
              type='text'
              id='productCountry'
              placeholder={intl.formatMessage({id: 'CLIENTS.COUNTRY'})}
              className='form-control'
              value={productCountry}
              onChange={(e) => setProductCountry(e.target.value)}
            />
            <div className='text-danger mb-6'>{errors.productCountry}</div>
          </div>

          <div className='col-6'>
            <input
              type='text'
              id='productState'
              placeholder={intl.formatMessage({id: 'CLIENTS.STATE'})}
              className='form-control'
              value={productState}
              onChange={(e) => setProductState(e.target.value)}
            />
            <div className='text-danger mb-6'>{errors.productState}</div>
          </div>
        </div>

        <div className='d-flex mt-4'>
          <div className='col-6 me-2'>
            <input
              type='text'
              id='productCity'
              placeholder={intl.formatMessage({id: 'CLIENTS.CITY'})}
              className='form-control'
              value={productCity}
              onChange={(e) => setProductCity(e.target.value)}
            />
            <div className='text-danger mb-6'>{errors.productCity}</div>
          </div>

          <div className='col-6'>
            <input
              type='text'
              id='productZone'
              placeholder={intl.formatMessage({id: 'CLIENTS.MUNICIPALITY'})}
              className='form-control'
              value={productZone}
              onChange={(e) => setProductZone(e.target.value)}
            />
            <div className='text-danger mb-6'>{errors.productZone}</div>
          </div>
        </div>
      </form>

      <button className='btn btn-light btn-sm mt-6 me-2' onClick={handlePrev}>
        {intl.formatMessage({id: 'CLIENTS.PREVIOUS'})}
      </button>
      <button className='btn btn-primary btn-sm mt-6' onClick={handleNext}>
        {intl.formatMessage({id: 'CLIENTS.NEXT'})}
      </button>
    </div>
  )
}

export {Step3}
