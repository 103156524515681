/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useContext} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useListView} from '../../../../modules/apps/user-management/users-list/core/ListViewProvider'
import {Dropdown1} from '../../../../../_metronic/partials'
import {useLocation} from 'react-router'
// import { UserEditModalForm } from '../../../usermmodal/UserEditModalForm';
import {UserContext} from '../../../../app-context/user-context/UserContext'
import {sellersEdit} from '../services/_SellersEditApi'
import {sessionExpired} from '../../../../utils/functions/_Functions'
import {AlertInfo} from '../../../../utils/interfaces/_InterfacesTypes'
import {useIntl} from 'react-intl'
import {productsListByClient} from '../../../products/services/_ProductsListApi'
import {ProductsForBrand} from '../../../brands/interfaces/brandsTypes'

export function SellersHeader() {
  const location = useLocation()
  const {setItemIdForUpdate} = useListView()
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)

  const [brandProductsList, setBrandProductsList] = useState<ProductsForBrand[]>([])

  const intl = useIntl()

  const {user, token, updateToken} = useContext(UserContext)

  // console.log(UserContext)

  const userName = user.usr_data.usr_name
  const userType = user.user_type
  const userEmail = user.usr_data.usr_mail
  const userPhone = user.usr_data.usr_phone
  const type = user.user_type
  const userToken = token
  const id = user.uid
  const clientId = user.cli_id

  const getProductsRegisters = async () => {
    try {
      const {data: jsonData, code, msg} = await productsListByClient(userToken, clientId)
      const productsListReceived = jsonData.data

      if (code === 401 && msg === 'Token no válido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      } else {
        const userTokenNew = await jsonData.token

        // console.log('actualiza token productsList (brands): ', userTokenNew)
        updateToken(userTokenNew)

        // console.log({productsListReceived});

        const productsSwitches = productsListReceived.map((product: ProductsForBrand) => {
          // console.log(productsListReceived)
          return {
            pro_id: product.pro_id,
            name: product.name,
          }
        })

        setBrandProductsList(productsSwitches)
      }
    } catch (error) {
      console.log('error de productos ', error)
    }
  }

  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    wha_phone: '',
    role: '',
    calendly: '',
    newpassword: '',
    verifypassword: '',
    brandProductsList: [],
  })

  const openAddUserModal = () => {
    setIsAddModalOpen(true) // Establecer el estado en true para mostrar el modal
    getProductsRegisters()
  }

  const handleEdit = async () => {
    console.log(formData)

    const name = formData.name
    const role = formData.role
    const email = formData.email
    const phone = formData.phone
    const whatsapp = formData.wha_phone
    const newpassword = formData.newpassword
    const verifypassword = formData.verifypassword
    const calendly = formData.calendly
    const status = true
    const brandProducts = []
    let usrPrivilage = 0

    // console.log(formData.brandProducts)
    // console.log(brandProducts)

    if (type === 'DEVELOPER' || type === 'SUPER_ADMIN') {
      usrPrivilage = 1
    }

    // console.log(usrPrivilage)

    try {
      // console.log('Valores del formulario:', id);

      // Llamar a la función que ejecuta el servicio del editar vendedor
      const {
        data: jsonData,
        code,
        msg,
      } = await sellersEdit(
        id,
        role,
        usrPrivilage,
        name,
        phone,
        whatsapp,
        email,
        newpassword,
        verifypassword,
        calendly,
        status,
        brandProducts,
        userToken
      )

      setAlertInfo({
        text:
          code === 200
            ? intl.formatMessage({id: 'CONTROL_SELLERS.USER_EDITED_SUCCESS'})
            : intl.formatMessage({id: 'GLOBAL.ALERT_SOMETHING_WENT_WRONG'}),
        variant: code === 200 ? 'success' : 'danger',
      })

      // Validación del mensaje y llamada a la función limpiarYRecargar
      if (msg === 'Token no válido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
      }

      setTimeout(() => {
        if (code === 200) {
          setAlertInfo({
            text: '',
            variant: '',
          })

          // closeModal()
        }
      }, 3000)

      console.log(msg)
    } catch (error) {
      setAlertInfo({
        text: 'Error en la solicitud Axios',
        variant: 'danger',
      })
    }
  }

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metronic' />
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                    {userName}
                  </a>
                  <a href='#'>
                    <KTIcon iconName='verify' className='fs-1 text-primary' />
                  </a>
                  <a
                    href='#'
                    className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_upgrade_plan'
                  >
                    {intl.formatMessage({id: 'CONTROL_SELLERS.UPGRADE_TO_PRO'})}
                  </a>
                </div>

                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                    {userType}
                  </a>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <KTIcon iconName='whatsapp' className='fs-4 me-1' />
                    {userPhone}
                  </a>
                  <a
                    href='#'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <KTIcon iconName='sms' className='fs-4 me-1' />
                    {userEmail}
                  </a>
                </div>
              </div>

              <div className='d-flex my-4'>
                <button
                  type='button'
                  className='btn btn-primary'
                  data-bs-toggle='modal'
                  data-bs-target='#kt_modal_1'
                  onClick={openAddUserModal}
                >
                  {intl.formatMessage({id: 'GLOBAL.EDIT'})}
                </button>
                <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                  <div className='modal-dialog modal-dialog-scrollable'>
                    <div className='modal-content'>
                      <div className='modal-header'>
                        <h5 className='modal-title'>
                          {intl.formatMessage({id: 'CONTROL_SELLERS.EDIT_USER'})}
                        </h5>
                        <div
                          className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                          data-bs-dismiss='modal'
                          aria-label='Close'
                        ></div>
                      </div>
                      <div className='modal-body' style={{maxHeight: '430px', overflowY: 'auto'}}>
                        {/* <UserEditModalForm formData={formData} setFormData={setFormData}/> */}
                      </div>
                      <div className='modal-footer'>
                        <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                          {intl.formatMessage({id: 'GLOBAL.CLOSE'})}
                        </button>
                        <button type='button' className='btn btn-primary' onClick={handleEdit}>
                          {intl.formatMessage({id: 'GLOBAL.SAVE_CHANGES'})}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Renderizar el modal si isAddModalOpen es true
                {isAddModalOpen && <UserEditModal />} */}
                <div className='me-0'>
                  {/* <button
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <i className='bi bi-three-dots fs-3'></i>
                  </button> */}
                  <Dropdown1 />
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                      <div className='fs-2 fw-bolder'>4500$</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>
                      {intl.formatMessage({id: 'CONTROL_SELLERS.MESSAGES'})}
                    </div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-down' className='fs-3 text-danger me-2' />
                      <div className='fs-2 fw-bolder'>75</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>
                      {intl.formatMessage({id: 'CONTROL_SELLERS.CLIENTS'})}
                    </div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                      <div className='fs-2 fw-bolder'>60%</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>
                      {intl.formatMessage({id: 'CONTROL_SELLERS.SCORE'})}
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6 text-gray-400'>
                    {intl.formatMessage({id: 'CONTROL_SELLERS.PROFILE_COMPLETION'})}
                  </span>
                  <span className='fw-bolder fs-6'>50%</span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/crafted/account/overview' && 'active')
                }
                to='/crafted/account/overview'
              >
                Overview
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/crafted/account/settings' && 'active')
                }
                to='/crafted/account/settings'
              >
                Settings
              </Link>
            </li>
          </ul>
        </div> */}
      </div>
    </div>
  )
}
