import React, { FC, useEffect, useState, useContext } from "react";
import {
    Column,
    Table,
    useReactTable,
    ColumnFiltersState,
    getCoreRowModel,
    getFilteredRowModel,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFacetedMinMaxValues,
    getPaginationRowModel,
    getSortedRowModel,
    FilterFn,
    ColumnDef,
    flexRender,
} from '@tanstack/react-table'

import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import { KTIcon } from '../../../../_metronic/helpers'
import { UserContext } from "../../../app-context/user-context/UserContext";
import { Button, Dropdown, DropdownButton, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { UsersSharedAccessList } from "../services/_ShareAccessApi";
import { sessionExpired } from "../../../utils/functions/_Functions";
import { ShareAccessList } from '../interfaces/shareAccessTypes';
import { ModalNewShareAccess } from "../modals/ModalNewShareAccess";
import { ModalEditShareAccess } from "../modals/ModalEditShareAccess";

declare module '@tanstack/table-core' {
    interface FilterFns {
        fuzzy: FilterFn<unknown>
    }
    interface FilterMeta {
        itemRank: RankingInfo
    }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value)

    // Store the itemRank info
    addMeta({
        itemRank,
    })

    // Return if the item should be filtered in/out
    return itemRank.passed
}

const ShareAccessMainTable = () => {

    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl()
    //Algunas constantes desde el userContext
    const { user, token, updateToken } = useContext(UserContext)
    const clientId: string = user.cli_id
    const userToken: string = token

    const handleCopyToClipboard = (text: string) => {
        // Intentar copiar el texto al portapapeles
        navigator.clipboard.writeText(text).catch((error) => {
            console.error('Error al copiar al portapapeles:', error)
            alert(intl.formatMessage({ id: 'LOGS.ERROR_COPY_CLIPBOARD' }))
        })
    }

    const renderTooltip = (props) => <Tooltip {...props}>{intl.formatMessage({ id: 'LINKDCEM.COPY_ID' })}</Tooltip>

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
    const [globalFilter, setGlobalFilter] = useState('')
    const [dataToExport, setDataToExport] = useState({});

    const [shareAccessList, setshareAccessList] = useState<ShareAccessList[]>([])

    const [showModalNew, setShowModalNew] = useState<boolean>(false)
    const [showModalEdit, setShowModalEdit] = useState<boolean>(false)
    const [registersForModals, setRegistersForModal] = useState<any>()

    const openModalNew = () => {
        setShowModalNew(true)
    }

    const closeModalNew = () => {
        setShowModalNew(false)
    }

    const openModalEdit = (lead) => {
        setShowModalEdit(true)
        setRegistersForModal(lead)
    }

    const closeModalEdit = () => {
        setShowModalEdit(false)
    }

    // Función para obtener la lista de usuarios con acceso compartido
    const getShareAccess = async () => {
        try {
            // Realizar la llamada a la API para obtener la lista de usuarios con acceso compartido
            const response = await UsersSharedAccessList(userToken, clientId);

            // Actualizar el token con el nuevo token obtenido de la respuesta
            const { usersShareAccess, token: newToken } = response;
            updateToken(newToken);

            // Obtener la lista de usuarios desde la respuesta y transformarla a un formato deseado
            const userSharedList = usersShareAccess.map((user) => ({
                user_name: user.usr_name,
                user_mail: user.usr_mail,
                lvl_access: user.lvl_access,
                evangelist: user.evangelist,
                status: user.status ? 'Activo' : 'Inactivo',
                user_id: user.user_id,
            }));

            // Actualizar el estado con la nueva lista de usuarios con acceso compartido
            setshareAccessList(userSharedList);
        } catch (error) {
            console.error('Error receiving SharedAccessList: ', error);
        }
    };

    useEffect(() => {
        getShareAccess()
    }, [])

    const columns = React.useMemo<ColumnDef<ShareAccessList, any>[]>(
        () => [
            {
                header: intl.formatMessage({ id: 'LINKDCEM.RECORDS_LINKS' }),
                footer: (props) => props.column.id,

                columns: [
                    {
                        accessorKey: 'user_id',
                        cell: (info) => (
                            <OverlayTrigger placement='top' overlay={renderTooltip}>
                                <button
                                    className='btn btn-light-primary'
                                    onClick={() => handleCopyToClipboard(info.getValue())}
                                >
                                    {info.getValue()}
                                </button>
                            </OverlayTrigger>
                        ),
                        header: () => <span>Id</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: 'user_name',
                        cell: (info) => info.getValue(),
                        header: () => <span>{intl.formatMessage({ id: 'GLOBAL.NAME' })}</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: 'user_mail',
                        cell: (info) => info.getValue(),
                        header: () => <span>{intl.formatMessage({ id: 'GLOBAL.MAIL' })}</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: 'lvl_access',
                        cell: (info) => info.getValue(),
                        header: () => <span>Nivel de acceso</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: 'status',
                        cell: (info) => {
                            const currentStatus = info.getValue()
                            let colorStatus: string = ''

                            switch (currentStatus) {
                                case 'Activo':
                                    colorStatus = 'success'
                                    break
                                case 'Inactivo':
                                    colorStatus = 'danger'
                                    break
                                default:
                                    colorStatus = ''
                                    break
                            }

                            return <span className={`badge badge-light-${colorStatus}`}>{currentStatus}</span>
                        },
                        header: () => <span>Estatus</span>,
                        footer: (props) => props.column.id,
                    },
                    {
                        accessorKey: 'actions',
                        cell: (info) => {
                            return (
                                <div>
                                    <button
                                        className='btn btn-primary btn-sm'
                                        onClick={() => {
                                            openModalEdit(info.row.original)
                                        }}
                                    >
                                        {intl.formatMessage({ id: 'GLOBAL.EDIT' })}
                                    </button>
                                </div>
                            )
                        },
                        header: () => <span>{intl.formatMessage({ id: 'GLOBAL.ACTIONS' })}</span>,
                        footer: (props) => props.column.id,
                    },
                ]
            }
        ],
        []
    )

    const table = useReactTable({
        data: shareAccessList,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        state: {
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        globalFilterFn: fuzzyFilter,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: true,
        debugHeaders: true,
        debugColumns: false,
    })

    React.useEffect(() => {
        if (table.getState().columnFilters[1]?.id === 'name') {
            if (table.getState().sorting[0]?.id !== 'name' || table.getState().sorting[0]?.desc) {
                table.setSorting([{ id: 'name', desc: false }])
            }
        }
    }, [table.getState().columnFilters[1]?.id])

    return (
        <div className='p-2'>
            <div className='card-header pt-6 pb-6'>
                <div className='d-flex justify-content-between'>
                    <DebouncedInput
                        value={globalFilter ?? ''}
                        onChange={(value) => setGlobalFilter(String(value))}
                        className='form-control form-control-solid w-250px ps-14 me-3'
                        placeholder={intl.formatMessage({ id: 'GLOBAL.TABLE_SEARCH_RECORD' })}
                    />

                    <button
                        type='button'
                        className='btn btn-primary d-flex align-items-center me-3'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    // onClick={handleSetData}
                    >
                        <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />{' '}
                        <span>{intl.formatMessage({ id: 'GLOBAL.DATA_EXPORT' })}</span>
                    </button>

                    {/* <DropdownExportLogs dataToExport={dataToExport} title={'Logs'} /> */}

                    {/* <button className='btn btn-primary me-3' onClick={() => handleExportToExcel()}>
              Descargar información
            </button> */}
                </div>

                {/* begin::Add Link dcem */}
                <button
                    type='button'
                    className='btn btn-primary me-3'
                    onClick={openModalNew}
                >
                    <KTIcon iconName='plus' className='fs-2' />
                    Compartir acceso
                </button>
                {/* end::Add Link dcem */}
            </div>
            <div className='h-2' />

            <div className='card-body table-responsive'>
                <table className='table table-row-bordered gs-3 gy-4 gx-12'>
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr
                                key={headerGroup.id}
                                className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
                            >
                                {headerGroup.headers.map((header) => {
                                    return (
                                        <th key={header.id} colSpan={header.colSpan}>
                                            {header.isPlaceholder ? null : (
                                                <>
                                                    <div
                                                        {...{
                                                            className: header.column.getCanSort()
                                                                ? 'cursor-pointer select-none'
                                                                : '',
                                                            onClick: header.column.getToggleSortingHandler(),
                                                        }}
                                                    >
                                                        {flexRender(header.column.columnDef.header, header.getContext())}
                                                        {{
                                                            asc: ' 🔼',
                                                            desc: ' 🔽',
                                                        }[header.column.getIsSorted() as string] ?? null}
                                                    </div>
                                                    {header.column.getCanFilter() ? (
                                                        <div>
                                                            <Filter column={header.column} table={table} />
                                                        </div>
                                                    ) : null}
                                                </>
                                            )}
                                        </th>
                                    )
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => {
                            return (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map((cell) => {
                                        return (
                                            <td key={cell.id}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <div className='h-2' />

            <div className='card-footer'>
                <div className='d-flex justify-content-between gap-2'>
                    <div id='SwitchPages'>
                        <button
                            className='btn btn-primary border rounded p-1'
                            onClick={() => table.setPageIndex(0)}
                            disabled={!table.getCanPreviousPage()}
                        >
                            {'<<'}
                        </button>
                        <button
                            className='btn btn-primary border rounded p-1'
                            onClick={() => table.previousPage()}
                            disabled={!table.getCanPreviousPage()}
                        >
                            {'<'}
                        </button>
                        <button
                            className='btn btn-primary border rounded p-1'
                            onClick={() => table.nextPage()}
                            disabled={!table.getCanNextPage()}
                        >
                            {'>'}
                        </button>
                        <button
                            className='btn btn-primary border rounded p-1'
                            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                            disabled={!table.getCanNextPage()}
                        >
                            {'>>'}
                        </button>
                    </div>

                    <div id='PagesFinder' className='text-center'>
                        {' '}
                        <span className='flex items-center gap-1'>
                            {intl.formatMessage({ id: 'GLOBAL.TABLE_FOOTER_PAGE' })}{' '}
                            <strong>
                                {table.getState().pagination.pageIndex + 1} {intl.formatMessage({ id: 'GLOBAL.TABLE_FOOTER_PAGE_OF' })} {table.getPageCount()}
                            </strong>{' '}
                            | {intl.formatMessage({ id: 'GLOBAL.TABLE_FOOTER_GO_TO_PAGE' })}:
                            <input
                                type='number'
                                defaultValue={table.getState().pagination.pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                                    table.setPageIndex(page)
                                }}
                                className='form-control form-control-solid w-16'
                            />
                        </span>
                    </div>

                    <div id='showFiles' className='text-center'>
                        <select
                            className='form-select form-select-solid'
                            value={table.getState().pagination.pageSize}
                            onChange={(e) => {
                                table.setPageSize(Number(e.target.value))
                            }}
                        >
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {intl.formatMessage({ id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_ONE' })} {pageSize}{' '}
                                    {intl.formatMessage({ id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_TWO' })}
                                </option>
                            ))}
                        </select>
                        <p>
                            {table.getPrePaginationRowModel().rows.length}{' '}
                            {intl.formatMessage({ id: 'GLOBAL.TABLE_FOOTER_TOTAL_RECORDS' })}
                        </p>
                    </div>
                </div>
            </div>

            <Modal
                show={showModalNew}
                onHide={closeModalNew}
                scrollable
                className="modal-default"
            >
                <Modal.Header
                    closeButton
                    onClick={closeModalNew}
                >
                    <Modal.Title>
                        <h1>Compartir acceso</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalNewShareAccess />
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className='btn btn-sm btn-secondari'
                        type='reset'
                        onClick={() => {
                            closeModalNew()
                        }}
                    >
                        Cancelar
                    </button>

                    <button
                        className='btn btn-sm btn-primary'
                        type='submit'
                    // onClick={() => {
                    //     closeModalLinkDetail()
                    // }}
                    >
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalEdit}
                onHide={closeModalEdit}
                scrollable
                className="modal-default"
            >
                <Modal.Header
                    closeButton
                    onClick={closeModalNew}
                >
                    <Modal.Title>
                        <h1>Editar registro</h1>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalEditShareAccess registersForModals={registersForModals}/>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className='btn btn-sm btn-secondari'
                        type='reset'
                        onClick={() => {
                            closeModalEdit()
                        }}
                    >
                        Cancelar
                    </button>

                    <button
                        className='btn btn-sm btn-primary'
                        type='submit'
                    // onClick={() => {
                    //     closeModalLinkDetail()
                    // }}
                    >
                        Guardar
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

function Filter({ column, table }: { column: Column<any, unknown>; table: Table<any> }) {
    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)
    //Variable para el uso de diccionario de lenguajes
    const intl = useIntl()
    const columnFilterValue = column.getFilterValue()

    const sortedUniqueValues = React.useMemo(
        () =>
            typeof firstValue === 'number'
                ? []
                : Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [column.getFacetedUniqueValues()]
    )

    // Especifica el ID de la columna para la que no deseas mostrar el filtro
    const excludedColumnId = 'actions' // Cambia esto al ID real de tu columna "acciones"

    // Si la columna actual es la que queremos excluir, no renderizamos el input de filtro
    if (column.id === excludedColumnId) {
        return (
            <>
                <br></br>
            </>
        ) // O puedes devolver un fragmento vacío: <></>
    }

    return typeof firstValue === 'number' ? (
        <div>
            <div className='flex space-x-2'>
                <DebouncedInput
                    type='number'
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[0] ?? ''}
                    onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
                    placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
                        }`}
                    className='w-24 border shadow rounded'
                />
                <DebouncedInput
                    type='number'
                    min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
                    max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
                    value={(columnFilterValue as [number, number])?.[1] ?? ''}
                    onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
                    placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
                        }`}
                    className='w-24 border shadow rounded'
                />
            </div>
            <div className='h-1' />
        </div>
    ) : (
        <>
            <datalist id={column.id + 'list'}>
                {sortedUniqueValues.slice(0, 5000).map((value: any) => (
                    <option value={value} key={value} />
                ))}
            </datalist>
            <DebouncedInput
                type='text'
                value={(columnFilterValue ?? '') as string}
                onChange={(value) => column.setFilterValue(value)}
                placeholder={`${intl.formatMessage({ id: 'GLOBAL.TABLE_SEARCH_THREE_DOTS' })} (${column.getFacetedUniqueValues().size})`}
                className='w-36 border shadow rounded'
                list={column.id + 'list'}
            />
            <div className='h-1' />
        </>
    )
}

// A debounced input react component
function DebouncedInput({
    value: initialValue,
    onChange,
    debounce = 500,
    ...props
}: {
    value: string | number
    onChange: (value: string | number) => void
    debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
    const [value, setValue] = React.useState(initialValue)

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            onChange(value)
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}

export { ShareAccessMainTable }