import React, {useEffect, useState, useContext} from 'react'
import {productsListByClient} from '../../products/services/_ProductsListApi'
import {UserContext} from '../../../app-context/user-context/UserContext'
import {useIntl} from 'react-intl'
import {sessionExpired} from '../../../utils/functions/_Functions'
import {LoadingAnimation} from '../../../utils/components/loading/LoadingAnimation'
import {ProductsForBrand} from '../../brands/interfaces/brandsTypes'

const ModalNewShareAccess = () => {
  // Importamos libreria para manejo de lenguaje
  const intl = useIntl()
  //Algunas variables desde el usercontext
  const {user, token, updateToken} = useContext(UserContext)
  const clientId = user.cli_id
  const userToken = token
  const [brandProductsList, setBrandProductsList] = useState([])
  //Constante para el manejo de estado de la animacion de carga
  const [isLoading, setIsLoading] = useState<boolean>(false)
  //Constante para el manejo de estado del mensaje post carga de registros
  const [infoMessageLoading, setInfoMessageLoading] = useState<string>('')
  const [isWabaBodyVisible, setIsWabaBodyVisible] = useState<boolean>(false)

  const checkProductsListLenght = () => {
    //Si el arreglo tiene un tamaño igual a cero
    if (brandProductsList.length === 0) {
      //Mandamos mensaje de productos no existentes
      setInfoMessageLoading(intl.formatMessage({id: 'BRANDS.MODAL_NEW.NO_PRODUCTS'}))
      //Ocultamos animacion de carga
      setIsLoading(false)
      //Si el arreglo tiene un tamaño mayor a cero
    } else {
      //Solo ocultamos animacion de carga
      setIsLoading(false)
    }
  }

  const getProductsRegisters = async () => {
    //Mostramos la animacion de carga
    setIsLoading(true)

    try {
      const {data: jsonData, code, msg} = await productsListByClient(userToken, clientId)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      } else {
        const productsListReceived = jsonData.data

        const userTokenNew = await jsonData.token

        // console.log('actualiza token productsList (brands): ', userTokenNew)
        updateToken(userTokenNew)

        // console.log({productsListReceived});

        const productsSwitches = productsListReceived.map((product) => {
          return {
            pro_id: product.pro_id,
            name: product.name,
          }
        })

        setBrandProductsList(productsSwitches)
        /* Ejecutamos la funcion para verificar  
                el tamaño del arreglo de marcas */
        checkProductsListLenght()
      }
    } catch (error) {
      setInfoMessageLoading(intl.formatMessage({id: 'BRANDS.MODAL_NEW.ERROR_PRODUCTS'}))
      setIsLoading(false)
      //  console.log('error de productos ', error);
    }
  }

  const handleLevelAccess = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {name, value} = e.target

    // if (value === 'Gerente') {
    //   setIsWabaBodyVisible(true)
    //   getProductsRegisters()
    // } else {
    //   setIsWabaBodyVisible(false)
    // }
  }
  //Hook para mostrar los productor a compartir.
  useEffect(() => {
    getProductsRegisters()
  }, [])

  return (
    <>
      <div className='px-7'>
        <div className='fv-row mb-7'>
          <div className='alert alert-danger d-flex align-items-center' role='alert'>
            <i className='ki-duotone ki-information-4 fs-4x'>
              <span className='path1'></span>
              <span className='path2'></span>
              <span className='path3'></span>
            </i>
            <div className='ml-6'>
              Antes de guardar tu registro, asegurate de verificar el correo del usuario al que
              darás acceso a tu cliente.
            </div>
          </div>
        </div>

        <div className='mb-5'>
          <div>
            <label className='fs-6 fw-bold required'>Mail</label>
            <input
              required
              type='text'
              name='mail'
              //   value={}
              //   onChange={handleInputChange}
              className='form-control form-control-solid mb-3 mb-lg-0'
              placeholder='Correo'
            />
          </div>
        </div>

        {/* <div className='mb-5'>
                    <label className='fs-6 fw-bold required'>
                        Nivel de acceso
                    </label>
                    <select
                        className='form-select form-select-solid'
                        name='lvlaccss'
                        onChange={(e) => {
                            handleLevelAccess(e)
                        }}
                    // value={formData.roleId}
                    >
                        <option value=''>
                            -- Selecciona nivel de acceso --
                        </option>
                        <option value='Administrator'>
                            Administrador
                        </option>
                        <option value='Gerente'>
                            Gerente
                        </option>

                    </select>
                </div> */}
        <div className='mb-5'>
          <label className='fs-6 fw-bold required'>Tipo de usuario</label>
          <select
            className='form-select form-select-solid'
            name='lvlaccss'
            onChange={(e) => {
              handleLevelAccess(e)
            }}
            // value={formData.roleId}
          >
            <option value=''>-- Selecciona tipo de usuario --</option>
            <option value='admin'>Admin</option>
            <option value='superAdmin'>Super admin</option>
            <option value='adminDc'>Admin DC</option>
            <option value='developer'>Developer</option>
            <option value='manager'>Manager</option>
            <option value='seller'>Seller</option>
            <option value='premiumSeller'>Premium seller</option>
            <option value='maketing'>Marketing</option>
            <option value='crm'>CRM</option>
            <option value='sistente'>Asistente</option>
            <option value='capturista'>Capturista</option>
          </select>
        </div>

        {
          <div className='mb-10'>
            <label className='fs-6 fw-bold mb-4'>Selecciona productos a compartir</label>
            <div style={{maxHeight: '250px', overflowY: 'auto'}}>
              {/* Validaciones para determinar que elementos mostraremos en la pantalla */}

              {/* Si isLoading es igual a 'true' lo mostramos */}
              {isLoading && (
                <LoadingAnimation
                  alignment='center'
                  label={intl.formatMessage({id: 'BRANDS.MODAL_NEW.LOADING_PRODUCTS'})}
                  size='lg'
                />
              )}

              {/* Si isLoading es diferente de 'true' y el tamaño de brandProductsList
          es mayor a 0 iniciamos el ciclo para pintar la lista de productos con su respectivo switch */}
              {!isLoading &&
                brandProductsList.length > 0 &&
                brandProductsList.map((item: ProductsForBrand) => (
                  <div
                    className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-4'
                    key={item.pro_id}
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name={`product_${item.pro_id}`}
                      //   checked={formData.brandProducts.includes(item.pro_id)}
                      //   onChange={handleInputChange}
                      value={item.pro_id}
                    />
                    <label className='form-check-label'>{item.name}</label>
                  </div>
                ))}

              {/* Si isLoading es diferente de 'true' y el tamaño de brandProductsList
          es igual a 0 mostramos el mensaje informativo */}
              {!isLoading && brandProductsList.length === 0 && <p>{infoMessageLoading}</p>}
            </div>
          </div>
        }
        {/* <div className='mb-5'>
                    <label className='fs-6 fw-bold required'>
                        Estatus de inicio
                    </label>
                    <select
                        className='form-select form-select-solid'
                        name='status'
                    // onChange={handleSelectChange}
                    // value={formData.roleId}
                    >
                        <option value=''>
                            -- Selecciona estatus inicial --
                        </option>
                        <option value='off'>
                            Inactive
                        </option>
                        <option value='on'>
                            Active
                        </option>

                    </select>
                </div> */}
      </div>
    </>
  )
}

export {ModalNewShareAccess}
