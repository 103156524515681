import {FC, useState, useEffect, useContext} from 'react'
import {useLocation} from 'react-router-dom'
import {LeadInfoCard} from './aux-components/cards/LeadInfoCard'
import {SellerInfoCard} from './aux-components/cards/SellerInfoCard'
import {RemindersCard} from './aux-components/cards/RemindersCard'
import {EndpointCard} from './aux-components/cards/EndpointCard'
import {InteractionsMenuCard} from './aux-components/cards/InteractionsMenuCard'
import {Lead, LeadDataDetail, LeadDetailInfo} from '../interfaces/leadsTypes'
import {LoadingAnimation} from '../../../utils/components/loading/LoadingAnimation'
import {leadDetailInfo} from '../services/_LeadsListApi'
import {UserContext} from '../../../app-context/user-context/UserContext'
import {sessionExpired} from '../../../utils/functions/_Functions'
import {useIntl} from 'react-intl'

export const LeadDetail: FC = () => {
  const location = useLocation()
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const query = new URLSearchParams(location.search)

  const leadId = query.get('leadId')

  const [leadIdValidate, setLeadIdValidate] = useState<number>(0)

  const [leadData, setLeadData] = useState<LeadDetailInfo>()

  //Algunas constantes desde el userContext
  const {user, token, updateToken} = useContext(UserContext)
  const clientId: string = user.cli_id
  const userToken: string = token

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [infoMessage, setInfoMessage] = useState<string>('')

  // const [lead, setLead] = useState(location.state)

  const getLeadData = async (leadId: string): Promise<void> => {
    console.log('inicia funcion para obtener datos del lead')
    setIsLoading(true)

    try {
      //Validar si existe datos en localstorage, si existe, eliminarlo.
      if (localStorage.getItem('leadPath')) {
        localStorage.removeItem('leadPath')
      }

      const {data: jsonData, code, msg} = await leadDetailInfo(userToken, leadId)

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.SESSION_EXPIRED'}))
        return
      } else if (code === 200) {
        const leadDetailInfo: LeadDetailInfo = jsonData.data

        const userTokenNew: string = jsonData.token

        updateToken(userTokenNew)

        console.log(leadDetailInfo)

        setLeadData(leadDetailInfo)
        setLeadIdValidate(1)
        setIsLoading(false)
      } else if (code !== 200) {
        setLeadIdValidate(3)
        setInfoMessage(intl.formatMessage({id: 'LEADS.INFO_LEAD_NOT_FOUND_PARAM'}))
        setIsLoading(false)
      }
    } catch (error) {
      console.log('something went wrong: ', error)
      setLeadIdValidate(2)
      setInfoMessage('Algo salio mal')
      setIsLoading(false)
    }
  }

  const validateLeadId = () => {
    if (leadId !== null && leadId !== undefined && leadId !== '') {
      setLeadIdValidate(1)
      getLeadData(leadId)
    } else {
      setLeadIdValidate(3)
      setInfoMessage(intl.formatMessage({id: 'GLOBAL.ALERT_SOMETHING_WENT_WRONG'}))
    }
  }

  useEffect(() => {
    // setLead(location.state)
    console.log('cambio el id')
    validateLeadId()
  }, [leadId])

  // console.log(lead)

  return (
    <>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-md-12'>
            <div className='row gy-10 gx-xl-12'>
              <div className='d-flex justify-content-between align-items-center'>
                <h2>{intl.formatMessage({id: 'LEADS.DETAIL_LEAD'})}</h2>
              </div>
            </div>
            {isLoading && (
              <LoadingAnimation
                alignment='center'
                size='lg'
                label={intl.formatMessage({id: 'LEADS.GET_LEAD_DATA'})}
              />
            )}
            {!isLoading && (
              <>
                {leadIdValidate === 1 ? (
                  <>
                    <div className='row mt-5'>
                      <div className='col-md-6'>
                        <LeadInfoCard leadData={leadData} />
                      </div>
                      <div className='col-md-6'>
                        <div>
                          <RemindersCard leadData={leadData} />
                        </div>
                        <div>
                          <EndpointCard leadData={leadData} />
                        </div>
                        <div>
                          <SellerInfoCard leadData={leadData} />
                        </div>
                      </div>
                    </div>
                    <div className='row mt-5'>
                      <div className='col-lg-12 col-md-12'>
                        <InteractionsMenuCard leadData={leadData} />
                      </div>
                    </div>
                  </>
                ) : leadIdValidate === 2 || leadIdValidate === 3 ? (
                  <div
                    className='alert alert-warning d-flex align-items-center justify-content-center text-center col-md-6 offset-md-3'
                    role='alert'
                  >
                    <span className='mb-1 text-center'>{infoMessage}</span>
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
