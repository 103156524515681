import React, {FC, useEffect, useState} from 'react'
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import {RankingInfo, rankItem} from '@tanstack/match-sorter-utils'
import {Button, Dropdown, DropdownButton, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Lead, LeadData} from '../interfaces/leadsTypes'
import {KTIcon} from '../../../../_metronic/helpers'
import {ModalGradeLead} from '../modals/ModalGradeLead'
import {ModalLeadToSpam} from '../modals/ModalLeadToSpam'
import {ModalReasignLead} from '../modals/ModalReasignLead'
import {ModalEditLead} from '../modals/ModalEditLead'
import {Link, useNavigate} from 'react-router-dom'
import {LeadDetail} from '../lead-detail/LeadDetail'
import {LeadsMainChart} from '../charts/LeadsMainChart'
import {LoadingAnimation} from '../../../utils/components/loading/LoadingAnimation'
import {DropdownExport} from '../../../../_metronic/partials/content/dropdown/DropdownExport'
import {useIntl} from 'react-intl'

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

type Props = {
  calculateChartData: any
  setLeadsDateData: React.Dispatch<React.SetStateAction<string[]>>
  setLeadsOriginalData: React.Dispatch<React.SetStateAction<number[]>>
  setLeadsDuplicateData: React.Dispatch<React.SetStateAction<number[]>>
  leadsList: Lead[]
  isLoading: boolean
  infoMessageLoading: string
  getLeadsByClient: () => void
  leadsInTable: (data: any[]) => void
}

export const LeadsMainTable: FC<Props> = ({
  calculateChartData,
  setLeadsDateData,
  setLeadsOriginalData,
  setLeadsDuplicateData,
  leadsList,
  isLoading,
  infoMessageLoading,
  getLeadsByClient,
  leadsInTable,
}) => {
  console.log('renderizamos componente de tabla')
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const handleCopyToClipboard = (text: string) => {
    // Intentar copiar el texto al portapapeles
    navigator.clipboard.writeText(text).catch((error) => {
      console.error('Error al copiar al portapapeles:', error)
      alert('Error al copiar al portapapeles. Por favor, inténtelo de nuevo.')
    })
  }

  const renderTooltip = (props) => (
    <Tooltip {...props}>{intl.formatMessage({id: 'GLOBAL.COPY_RECORD_ID'})}</Tooltip>
  )

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')

  // const [leadsList, setLeadsList] = useState<Lead[]>([])

  //Constantes para manejar el estado del modal de calificar lead
  const [showModalLeadGrade, setshowModalLeadGrade] = useState<boolean>(false)

  //Constantes para manejar el estado del modal de calificar lead
  const [showModalLeadToSpam, setshowModalLeadToSpam] = useState<boolean>(false)

  //Constantes para manejar el estado del modal de reasignar lead
  const [showModalLeadReasign, setShowModalLeadReasign] = useState<boolean>(false)

  //Constantes para manejar el estado del modal de edicion de lead
  const [showModalLeadEdit, setShowModalLeadEdit] = useState<boolean>(false)

  //Definimos variable para guardar informacion del lead
  const [leadData, setLeadData] = useState({})
  const [leadforModalsData, setLeadForModalsData] = useState<any>()

  //Funcion para abrir el modal para calificar un lead
  const openModalLeadGrade = (lead) => {
    setshowModalLeadGrade(true)
    setLeadForModalsData(lead)
  }

  //Funcion para cerrar el modal para calificar lead
  const closeModalLeadGrade = () => {
    setshowModalLeadGrade(false)
  }

  //Funcion para abrir el modal para enviar un lead a SPAM
  const openModalLeadToSpam = (lead) => {
    setshowModalLeadToSpam(true)
    setLeadForModalsData(lead)
  }

  //Funcion para cerrar el modal para enviar un lead a SPAM
  const closeModalLeadToSpam = () => {
    setshowModalLeadToSpam(false)
  }

  //Funcion para abrir el modal de reasignacion de lead
  const openModalLeadReasign = (lead) => {
    setShowModalLeadReasign(true)
    setLeadForModalsData(lead)
  }

  //Funcion para cerrar el modal de reasignacion de lead
  const closeModalLeadReasign = () => {
    setShowModalLeadReasign(false)
  }

  //Funcion para abrir el modal de edicion de lead
  const openModalLeadEdit = (lead) => {
    setShowModalLeadEdit(true)
    setLeadForModalsData(lead)
  }

  //Funcion para cerrar el modal de edicion de lead
  const closeModalLeadEdit = () => {
    setShowModalLeadEdit(false)
  }

  //Funcion para navegar a la apgina de detalle de lead pasando el objeto lead
  const navigate = useNavigate()
  const toLeadDetail = (lead: Lead) => {
    const leadId: string = lead.lead_id
    // navigate('/lead-detail/', { state: lead })
    navigate(`/lead-detail?leadId=${leadId}`)
  }

  const toAssignModule = (lead: Lead) => {
    if(lead.roleId && lead.roleName){
      const roleId: string = lead.roleId
      const roleName: string = lead.roleName
      navigate(`/assigns?roleId=${roleId}&roleName=${roleName}`)
    }
  }

  // const navigate = useNavigate()
  // const toLeadDetail = (lead) => {
  //   navigate('/lead-detail/', { state: lead })
  // }

  // // Función para manejar los cambios en los datos filtrados
  const handleFilteredChange = () => {
    const filteredData = table.getFilteredRowModel().rows.map((row) => row.original)
    console.log(filteredData)

    // Uso de la función para calcular los datos
    const {originalLeadsData, duplicateLeadsData, datesData} = calculateChartData(filteredData)
    console.log(datesData)
    console.log(originalLeadsData)
    console.log(duplicateLeadsData)

    setLeadsDateData(datesData)
    setLeadsOriginalData(originalLeadsData)
    setLeadsDuplicateData(duplicateLeadsData)
  }

  const columns = React.useMemo<ColumnDef<Lead, any>[]>(
    () => [
      {
        header: intl.formatMessage({id: 'LEADS.LEADS_REGISTER'}),
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'lead_id',
            cell: (info) => (
              <OverlayTrigger placement='top' overlay={renderTooltip}>
                <button
                  className='btn btn-light-primary'
                  onClick={() => handleCopyToClipboard(info.getValue())}               
                >
                  {info.getValue()}
                </button>
              </OverlayTrigger>
            ),
            header: () => <span>Id</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'lead_date',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.DATE'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'pro_name', // Nueva clave de acceso para la combinación de teléfono y WhatsApp
            cell: (info) => (
              <div>
                <p className='fw-bold'>{info.row.original.pro_name}</p>
                <p>{info.row.original.cam_name}</p>
              </div> // Mostrar teléfono y WhatsApp separados por /
            ),
            filterValue: (rows, id, filterValue) => {
              return rows.filter((row) => row.original.pro_name.includes(filterValue))
            },
            header: () => (
              <span>
                {intl.formatMessage({id: 'GLOBAL.PRODUCT'})} |{' '}
                {intl.formatMessage({id: 'GLOBAL.CAMPAIGN'})}
              </span>
            ),
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'lead_data.name',
            cell: (info) => {
              const spam: boolean = info.row.original.spamFlag

              return (
                <>
                  <p className='fw-bold'>{info.row.original.lead_data.name}</p>
                  <p>{info.row.original.lead_data.lead_mail}</p>
                  <p>{info.row.original.lead_data.lead_phone}</p>

                  {spam && (
                    <span className={`badge badge-light-danger p-2`}>
                      <KTIcon iconName='shield-cross' className='fs-3 text-danger' />
                      SPAM
                    </span>
                  )}
                </> // Mostrar teléfono y WhatsApp separados por /
              )
            },
            filterValue: (rows, id, filterValue) => {
              return rows.filter((row) => row.original.lead_data.name.includes(filterValue))
            },
            header: () => <span>{intl.formatMessage({id: 'LEADS.LEAD_DATA'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'seller_name',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.SELLER'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'roleName',
            cell: (info) => {

              // const roleName = info.getValue()
              let nameRol: string | undefined;
              let idRol: string | undefined;
              let color: string;
              let visible: boolean;

              if(info.getValue()){
                nameRol = info.row.original.roleName
                idRol = info.row.original.roleId
                color = 'primary'
                visible = false
              }else{
                nameRol = 'Sin rol'
                idRol = '00'
                color='danger'
                visible = true
              }

              return (
                <>  
                  <button 
                    className={`btn btn-sm btn-${color} px-5 py-3`} 
                    disabled={visible}
                    onClick={() => {
                      toAssignModule(info.row.original)
                    }}
                  >
                    {nameRol}
                  </button>
                </>
              )
            },
            header: () => <span>{intl.formatMessage({id: 'LEADS.DETAIL_ASSIGN'})}</span>,
            footer: (props) => props.column.id,

          },
          {
            accessorKey: 'leadGrade',
            cell: (info) => {
              const leadGrade: string = info.getValue()
              let color: string = ''

              if (leadGrade === 'sin calificación') {
                color = 'dark'
              } else {
                if (leadGrade == '1' || leadGrade == '2') {
                  color = 'danger'
                } else if (leadGrade == '3' || leadGrade == '4') {
                  color = 'warning'
                } else {
                  color = 'success'
                }
              }

              return (
                <>
                  <span className={`badge badge-light-${color} p-2`}>{`${intl.formatMessage({id: 'LEADS.LAST_RATING'})}: ${leadGrade}`}</span>
                  <br />
                  <br />
                  <button
                    className='btn btn-sm btn-primary px-3 py-1'
                    onClick={() => {
                      openModalLeadGrade(info.row.original)
                    }}
                  >
                    {intl.formatMessage({id: 'GLOBAL.RATE'})}
                  </button>
                </>
              )
            },
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.SCORE'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'leadIscore',
            cell: (info) => {
              const leadScore: number = info.getValue()
              let color: string = ''

              if (leadScore >= 40) {
                color = 'success'
              } else if (leadScore < 40 && leadScore > 20) {
                color = 'warning'
              } else {
                color = 'danger'
              }

              return <span className={`badge badge-light-${color} p-2`}>{leadScore}</span>
            },
            header: () => <span>{intl.formatMessage({id: 'LEADS.ISCORE_LEAD'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'seller_score',
            cell: (info) => {
              const sellerScore: number = info.getValue()
              let color: string = ''

              if (sellerScore >= 40) {
                color = 'success'
              } else if (sellerScore < 40 && sellerScore > 20) {
                color = 'warning'
              } else {
                color = 'danger'
              }
              return <span className={`badge badge-light-${color} p-2`}>{sellerScore}</span>
            },
            header: () => <span>{intl.formatMessage({id: 'LEADS.ISCORE_SELLER'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'medium',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.MEDIUM'})}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'actions',
            cell: (info) => {
              const spamFlag: boolean = info.row.original.spamFlag
              return (
                <div>
                  <DropdownButton
                    size='sm'
                    id={`dropdown-${info.row.original.lead_id}`}
                    title={intl.formatMessage({id: 'GLOBAL.ACTIONS'})}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        toLeadDetail(info.row.original)
                      }}
                    >
                      {intl.formatMessage({id: 'LEADS.DETAIL_LEAD'})}
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={spamFlag}
                      onClick={() => {
                        openModalLeadReasign(info.row.original)
                      }}
                    >
                      {intl.formatMessage({id: 'LEADS.LEAD_REASSIGN'})}
                    </Dropdown.Item>

                    <Dropdown.Item
                      disabled={spamFlag}
                      onClick={() => {
                        openModalLeadEdit(info.row.original)
                      }}
                    >
                      {intl.formatMessage({id: 'LEADS.EDIT_LEAD'})}
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={spamFlag}
                      onClick={() => {
                        openModalLeadToSpam(info.row.original)
                      }}
                    >
                      {intl.formatMessage({id: 'LEADS.SENT_TO_SPAM'})}
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              )
            },
            header: () => <span>{intl.formatMessage({id: 'GLOBAL.ACTIONS'})}</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    []
  )

  const table = useReactTable({
    data: leadsList,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  //Disparamos la funcion con un useEffect
  useEffect(() => {
    handleFilteredChange()
  }, [table.getState().columnFilters, table.getState().globalFilter])

  // useEffect(() => {
  //   // Cargar datos de leads al montar el componente
  //   getLeadsByClient()
  // }, []) // Se ejecuta solo una vez al montar el componente

  React.useEffect(() => {
    if (table.getState().columnFilters[1]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name' || table.getState().sorting[0]?.desc) {
        table.setSorting([{id: 'name', desc: false}])
      }
    }
  }, [table.getState().columnFilters[1]?.id])

  //Useefect para detectar cambios en la tabla (filtros) y actualizar los datos para el botón de exportación
  useEffect(() => {
    // Obtener las filas actuales de la tabla
    const rows = table.getPrePaginationRowModel().flatRows.map((row) => row.original)
    leadsInTable(rows)
  }, [table.getPrePaginationRowModel().rows])

  return (
    <div className='p-2'>
      <div className='card-header pt-6 pb-6'>
        <div className='d-flex justify-content-between'>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className='form-control form-control-solid w-250px me-3'
            placeholder={intl.formatMessage({id: 'GLOBAL.TABLE_SEARCH_THREE_DOTS'})}
          />
        </div>
      </div>

      <div className='h-2' />
      <div className='card-body table-responsive'>
        <table className='table table-row-bordered gs-3 gy-4 gx-12'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className='h-2' />

      <div className='card-footer'>
        <div className='d-flex justify-content-between gap-2'>
          <div id='SwitchPages'>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
          </div>

          <div id='PagesFinder' className='text-center'>
            {' '}
            <span className='flex items-center gap-1'>
              {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE'})}{' '}
              <strong>
                {table.getState().pagination.pageIndex + 1} {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_PAGE_OF'})} {table.getPageCount()}
              </strong>{' '}
              | {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_GO_TO_PAGE'})}:
              <input
                type='number'
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
                className='form-control form-control-solid w-16'
              />
            </span>
          </div>

          <div id='showFiles' className='text-center'>
            <select
              className='form-select form-select-solid'
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_ONE'})} {pageSize}{' '}
                  {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_TWO'})}
                </option>
              ))}
            </select>
            <p>
              {table.getPrePaginationRowModel().rows.length}{' '}
              {intl.formatMessage({id: 'GLOBAL.TABLE_FOOTER_TOTAL_RECORDS'})}
            </p>
          </div>
        </div>
      </div>

      {/* Modal para calificar un lead */}
      <Modal
        show={showModalLeadGrade}
        onHide={closeModalLeadGrade}
        scrollable
        className='modal-default'
      >
        <ModalGradeLead
          lead={leadforModalsData}
          closeModalLeadGrade={closeModalLeadGrade}
          getLeadsByClient={getLeadsByClient}
        />
      </Modal>

      {/* Modal para enviar a SPAM un lead */}
      <Modal
        show={showModalLeadToSpam}
        onHide={closeModalLeadToSpam}
        scrollable
        className='modal-default'
      >
        <ModalLeadToSpam
          lead={leadforModalsData}
          closeModalLeadToSpam={closeModalLeadToSpam}
          getLeadsByClient={getLeadsByClient}
          origin='leads'
        />
      </Modal>

      {/* Modal para reasignar un lead */}
      <Modal
        show={showModalLeadReasign}
        onHide={closeModalLeadReasign}
        scrollable
        className='modal-default'
      >
        <ModalReasignLead
          lead={leadforModalsData}
          getLeadsByClient={getLeadsByClient}
          closeModalLeadReasign={closeModalLeadReasign}
        />
      </Modal>

      {/* Modal para editar un lead */}
      <Modal
        show={showModalLeadEdit}
        onHide={closeModalLeadEdit}
        scrollable
        className='modal-default'
      >
        <ModalEditLead
          lead={leadforModalsData}
          getLeadsByClient={getLeadsByClient}
          closeModalLeadEdit={closeModalLeadEdit}
        />
      </Modal>
    </div>
  )
}

function Filter({column, table}: {column: Column<any, unknown>; table: Table<any>}) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)
   //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  // Especifica el ID de la columna para la que no deseas mostrar el filtro
  const excludedColumnId = 'actions' // Cambia esto al ID real de tu columna "acciones"

  // Si la columna actual es la que queremos excluir, no renderizamos el input de filtro
  if (column.id === excludedColumnId) {
    return (
      <>
        <br></br>
      </>
    ) // O puedes devolver un fragmento vacío: <></>
  }

  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
          }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`${intl.formatMessage({id: 'GLOBAL.TABLE_SEARCH_THREE_DOTS'})} (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded'
        list={column.id + 'list'}
      />
      <div className='h-1' />
    </>
  )
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}
