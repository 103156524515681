import React, { FC, useEffect, useState } from 'react'
import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  FilterFn,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import { KTIcon } from '../../../../_metronic/helpers'
import { DropdownExportLogs } from '../../../../_metronic/partials/content/dropdown/DropdownExportLogs'
import { Button, Dropdown, DropdownButton, DropdownItem, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { LinkDCEM } from '../interfaces/linksDcemTypes'
import { ModalDcemDetail } from '../modals/ModalDcemDetail'
import { ModalDcemEdit } from '../modals/ModalDcemEdit'
import { useIntl } from 'react-intl'

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

type Props = {
  openModalLinkDcemNew: any
}

export const LinksDcemMainTable: FC<Props> = ({
  openModalLinkDcemNew
}) => {
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  const handleCopyToClipboard = (text: string) => {
    // Intentar copiar el texto al portapapeles
    navigator.clipboard.writeText(text).catch((error) => {
      console.error('Error al copiar al portapapeles:', error)
      alert(intl.formatMessage({ id: 'LOGS.ERROR_COPY_CLIPBOARD' }))
    })
  }

  const renderTooltip = (props) => <Tooltip {...props}>{intl.formatMessage({ id: 'LINKDCEM.COPY_ID' })}</Tooltip>

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const [dataToExport, setDataToExport] = useState({});

  const [linksDcemList, setLinksDcemList] = useState<LinkDCEM[]>([])

  const [showModalLinkDetail, setShowModalLinkDetail] = useState<boolean>(false)

  const [showModalLinkEdit, setShowModalLinkEdit] = useState<boolean>(false)

  const [linksForModalsData, setLinksForModalsData] = useState<any>()

  //Función para definir la data que se va a exportar
  const handleSetData = () => {
    const dataToExport = table.getRowModel().flatRows.map((row) => row.original);
    setDataToExport(dataToExport)
  }

  //Funcion para abrir el modal de detalle del link
  const openModalLinkDetail = (linkDcem) => {
    setLinksForModalsData(linkDcem)
    setShowModalLinkDetail(true)
  }

  //Funcion para cerrar el modal de detalle del link
  const closeModalLinkDetail = () => {
    setShowModalLinkDetail(false)
  }

  //Funcion para abrir el modal de edicion de link
  const openModalLinkEdit = (linkDcem) => {
    setLinksForModalsData(linkDcem)
    setShowModalLinkEdit(true)
  }

  //Funcion para cerrar el modal de edicion de link
  const closeModalLinkEdit = () => {
    setShowModalLinkEdit(false)
  }

  //Funcion de descarga archivo qr
  const downloadQR = (url) => {
    window.open(url, '_blank');
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', '');
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  }

  //Esta es la peticion con la que traeremos los links dcem
  const getLinksDcem = () => {
    const newLinksDcem = [
      {
        linkId: 'DCEM-2346-API',
        linkName: 'LUA',
        shortedLink: 'https://dcem.mx/ciyn',
        dateAdd: '2022-11-01 11:37:32',
        dateUpdate: '2023-10-24 14:56:44',
        originalLink: 'https://bryan-rodriguez-cv.tiiny.site',
        autorLinkId: 'POOK-554-LOO',
        autorLinkName: 'Andy Bryan Rodriguez',
        totalClicks: 43,
        qrRoute: 'https://storage.googleapis.com/dragon-bucket-prod/app/img/qrs/link_dcem_LUA.png',
      },
      {
        linkId: 'DCEM-2345-API',
        linkName: 'Apardo Calendly',
        shortedLink: 'https://https://dcem.mx/apardo.mx/ciyn',
        dateAdd: '2022-11-01 12:15:29',
        dateUpdate: '2023-10-24 14:56:44',
        originalLink: 'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0_PZrGBwuexuvbDlIUf6o8YKwgnohl-Pjpx13zDYe0CtFP3DqwQqTC0iRaf8Qv02Ojk2AfPxH0',
        autorLinkId: 'POOK-234-APD',
        autorLinkName: 'Arturo D. Pardo',
        totalClicks: 108,
      }
    ]
    setLinksDcemList(newLinksDcem)
  }

  useEffect(() => {
    getLinksDcem()
  }, [])


  const columns = React.useMemo<ColumnDef<LinkDCEM, any>[]>(
    () => [
      {
        header: intl.formatMessage({ id: 'LINKDCEM.RECORDS_LINKS' }),
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'linkId',
            cell: (info) => (
              <OverlayTrigger placement='top' overlay={renderTooltip}>
                <button
                  className='btn btn-light-primary'
                  onClick={() => handleCopyToClipboard(info.getValue())}
                >
                  {info.getValue()}
                </button>
              </OverlayTrigger>
            ),
            header: () => <span>Id</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'linkName',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({ id: 'GLOBAL.NAME' })}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'shortedLink', // Nueva clave de acceso para la combinación de teléfono y WhatsApp
            cell: (info) => (
              <div>
                <p>{info.row.original.shortedLink}</p>
              </div> // Mostrar teléfono y WhatsApp separados por /
            ),
            header: () => <span>{intl.formatMessage({ id: 'GLOBAl.DESCRIPTION' })}</span>,
          },
          {
            accessorKey: 'dateAdd',
            id: 'dateAdd',
            cell: (info) => info.getValue(),
            header: () => <span>{intl.formatMessage({ id: 'GLOBAL.DATE' })}</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'acctions',
            cell: (info) => {
              return (
                <div>
                  <DropdownButton
                    size='sm'
                    id={`dropdown-${info.row.original.linkId}`}
                    title={intl.formatMessage({ id: 'GLOBAL.ACTIONS' })}
                  >
                    <DropdownItem
                      onClick={() => {
                        openModalLinkDetail(info.row.original)
                      }}
                    >
                      {intl.formatMessage({ id: 'GLOBAL.DETAIL' })}
                    </DropdownItem>

                    <DropdownItem
                      onClick={() => {
                        openModalLinkEdit(info.row.original)
                      }}
                    >
                      {intl.formatMessage({ id: 'GLOBAL.EDIT' })}
                    </DropdownItem>

                  </DropdownButton>
                </div>
              )
            }
          }
        ],
      },
    ],
    []
  )

  const table = useReactTable({
    data: linksDcemList,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })

  React.useEffect(() => {
    if (table.getState().columnFilters[1]?.id === 'name') {
      if (table.getState().sorting[0]?.id !== 'name' || table.getState().sorting[0]?.desc) {
        table.setSorting([{ id: 'name', desc: false }])
      }
    }
  }, [table.getState().columnFilters[1]?.id])

  return (
    <div className='p-2'>
      <div className='card-header pt-6 pb-6'>
        <div className='d-flex justify-content-between'>
          <DebouncedInput
            value={globalFilter ?? ''}
            onChange={(value) => setGlobalFilter(String(value))}
            className='form-control form-control-solid w-250px ps-14 me-3'
            placeholder={intl.formatMessage({ id: 'GLOBAL.TABLE_SEARCH_RECORD' })}
          />

          <button
            type='button'
            className='btn btn-primary d-flex align-items-center me-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={handleSetData}
          >
            <KTIcon iconType='outline' iconName='file-down' className='fs-2 me-2' />{' '}
            <span>{intl.formatMessage({ id: 'GLOBAL.DATA_EXPORT' })}</span>
          </button>

          <DropdownExportLogs dataToExport={dataToExport} title={'Logs'} />

          {/* <button className='btn btn-primary me-3' onClick={() => handleExportToExcel()}>
              Descargar información
            </button> */}
        </div>

        {/* begin::Add Link dcem */}
        <button
          type='button'
          className='btn btn-primary me-3'
          onClick={openModalLinkDcemNew}
        >
          <KTIcon iconName='plus' className='fs-2' />
          {intl.formatMessage({ id: 'LINKDCEM.CREATE_LINKDCEM' })}
        </button>
        {/* end::Add Link dcem */}
      </div>
      <div className='h-2' />

      <div className='card-body table-responsive'>
        <table className='table table-row-bordered gs-3 gy-4 gx-12'>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'
              >
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {{
                              asc: ' 🔼',
                              desc: ' 🔽',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className='h-2' />

      <div className='card-footer'>
        <div className='d-flex justify-content-between gap-2'>
          <div id='SwitchPages'>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className='btn btn-primary border rounded p-1'
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
          </div>

          <div id='PagesFinder' className='text-center'>
            {' '}
            <span className='flex items-center gap-1'>
              {intl.formatMessage({ id: 'GLOBAL.TABLE_FOOTER_PAGE' })}{' '}
              <strong>
                {table.getState().pagination.pageIndex + 1} {intl.formatMessage({ id: 'GLOBAL.TABLE_FOOTER_PAGE_OF' })} {table.getPageCount()}
              </strong>{' '}
              | {intl.formatMessage({ id: 'GLOBAL.TABLE_FOOTER_GO_TO_PAGE' })}:
              <input
                type='number'
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
                className='form-control form-control-solid w-16'
              />
            </span>
          </div>

          <div id='showFiles' className='text-center'>
            <select
              className='form-select form-select-solid'
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {intl.formatMessage({ id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_ONE' })} {pageSize}{' '}
                  {intl.formatMessage({ id: 'GLOBAL.TABLE_FOOTER_SHOW_PART_TWO' })}
                </option>
              ))}
            </select>
            <p>
              {table.getPrePaginationRowModel().rows.length}{' '}
              {intl.formatMessage({ id: 'GLOBAL.TABLE_FOOTER_TOTAL_RECORDS' })}
            </p>
          </div>
        </div>
      </div>

      {/* Modal para ver el detalle de un link */}
      <Modal
        show={showModalLinkDetail}
        onHide={closeModalLinkDetail}
        scrollable
        className='modal-lg'
      >
        <Modal.Header closeButton onClick={closeModalLinkDetail}>
          <Modal.Title>
            <h1>{intl.formatMessage({ id: 'LINKDCEM.LINKDCEM_DETAIL' })}</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalDcemDetail linkDcem={linksForModalsData} />
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-sm btn-primary'
            type='submit'
            onClick={() => {
              downloadQR(linksForModalsData.qrRoute)
            }}
          >
            {intl.formatMessage({ id: 'LINKDCEM.DOWNLOAD_QR' })}
          </button>

          <button
            className='btn btn-sm btn-secondary'
            type='reset'
            onClick={() => {
              closeModalLinkDetail()
            }}
          >
            {intl.formatMessage({ id: 'GLOBAL.CLOSE' })}
          </button>
        </Modal.Footer>
      </Modal>

      {/* Modal para edicion de link */}
      <Modal
        show={showModalLinkEdit}
        onHide={closeModalLinkEdit}
        scrollable
        className='modal-default'
      >
        <Modal.Header closeButton onClick={closeModalLinkEdit}>
          <Modal.Title>
            {intl.formatMessage({ id: 'LINKDCEM.EDIT_LINKDCEM' })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalDcemEdit linkDcem={linksForModalsData} />
        </Modal.Body>
        <Modal.Footer>
          <button
            type='submit'
            className='btn btn-sm btn-primary'
          >
            {intl.formatMessage({ id: 'GLOBAL.SAVE' })}
          </button>
          <button
            type='reset'
            className='btn btn-sm btn-secondary'
            onClick={() => {
              closeModalLinkEdit()
            }}
          >
            {intl.formatMessage({ id: 'GLOBAL.CANCEL' })}
          </button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

function Filter({ column, table }: { column: Column<any, unknown>; table: Table<any> }) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id)
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()
  const columnFilterValue = column.getFilterValue()

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  )

  return typeof firstValue === 'number' ? (
    <div>
      <div className='flex space-x-2'>
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [value, old?.[1]])}
          placeholder={`Min ${column.getFacetedMinMaxValues()?.[0] ? `(${column.getFacetedMinMaxValues()?.[0]})` : ''
            }`}
          className='w-24 border shadow rounded'
        />
        <DebouncedInput
          type='number'
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={(value) => column.setFilterValue((old: [number, number]) => [old?.[0], value])}
          placeholder={`Max ${column.getFacetedMinMaxValues()?.[1] ? `(${column.getFacetedMinMaxValues()?.[1]})` : ''
            }`}
          className='w-24 border shadow rounded'
        />
      </div>
      <div className='h-1' />
    </div>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`${intl.formatMessage({ id: 'GLOBAL.TABLE_SEARCH_THREE_DOTS' })} (${column.getFacetedUniqueValues().size})`}
        className='w-36 border shadow rounded'
        list={column.id + 'list'}
      />
      <div className='h-1' />
    </>
  )
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}

// export { LinksDCEMMainTable }