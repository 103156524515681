// Importamos hooks de react que usaremos para este componente
import { FC, useState } from "react";
// Importamos componentes Modal, Overlaytrigger y Tooltip de la libreria de bootstrap para react
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
// Importamos helpers de metronic
import { KTIcon } from "../../../../_metronic/helpers";
// Importamos funcionalidad Link para ruteo de paginas
import { Link } from "react-router-dom";
// Importamos libreria para manejo de lenguaje 
import { useIntl } from "react-intl";

// ---------------------------------------------------------------------------------------

// Importamos el componente ModalEditIscores
import { ModalEditIscores } from "../modals/ModalEditIscores";
// Importamos el componente ModalEditProduct
import { ModalEditProduct } from "../modals/ModalEditProduct";
// Importamos el componente ProductsHistoryTable para el modal de historial de cambios
import { ProductsHistoryTable } from "../tables/ProductsHistoryTable";
// Importamos contenido para el export de registros de vendedores asignados a productos
import { DropdownExportSellersAssignedInProduct } from "../../../../_metronic/partials/content/dropdown/DropdownExportSellersAssignedInProduct";

// ---------------------------------------------------------------------------------------

// Importamos las interfaces de productos que usaremos para este componente
import { Address, DataProduct, DataTwl, FormDataEdit, IScore, FormDataEditSeller, FormDataEditLead } from "../interfaces/productsTypes";

// Importamos constantes
import { BucketMediaURL } from "../../../utils/constants/_Constants";

//Definimos tipado de las propiedades (props)
type Props = {
  infoProduct: Array<DataProduct>
  twlProduct: Array<DataTwl>
  dateDownProduct: string
  sellWabStatusProduct: boolean
  smsStatusProduct: boolean
  wabMdlStatusProduct: boolean
  wabStatusProduct: boolean
  campaignsProduct: string[]
  idProduct: string
  addressProduct: Array<Address>
  crmIdProduct: string
  statusProduct: number
  nameProduct: string
  typeProduct: string
  dateAddProduct: string
  parserProduct: string
  mailsProduct: string[]
  legalsProduct: string
  priceProduct: number
  countryProduct: string
  stateProduct: string
  cityProduct: string
  zoneProduct: string
  gmapsProduct: string
  wazeProduct: string
  webProduct: string
  virtualProduct: string
  introProduct: string
  merchPdfProduct: string
  logoProduct: string
  headerProduct: string
  whaImgProduct: string
  // reRenderProducts: () => void
  campaignsActive: number
  getProductsRegisters: () => void
}

//Iniciamos funcion para construir componente CardProducts
export const CardProducts: FC<Props> = ({
  infoProduct,
  twlProduct,
  dateDownProduct,
  sellWabStatusProduct,
  smsStatusProduct,
  wabMdlStatusProduct,
  wabStatusProduct,
  campaignsProduct,
  idProduct,
  addressProduct,
  crmIdProduct,
  statusProduct,
  nameProduct,
  typeProduct,
  dateAddProduct,
  parserProduct,
  mailsProduct,
  legalsProduct,
  priceProduct,
  countryProduct,
  stateProduct,
  cityProduct,
  zoneProduct,
  gmapsProduct,
  wazeProduct,
  webProduct,
  virtualProduct,
  introProduct,
  merchPdfProduct,
  logoProduct,
  headerProduct,
  whaImgProduct,
  // reRenderProducts,
  campaignsActive,
  getProductsRegisters,
}) => {

  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl();

  //Variables para manejo de estado de mostrar o no el modal de edicion
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false);

  //Constante para manejo de estado del modal de historial
  const [showModalHistory, setShowModalHistory] = useState<boolean>(false);

  //Variables para manejo de estado de mostrar o no el modal de edicion de iscore
  const [showModalEditIscore, setShowModalEditIscore] = useState<boolean>(false);

  //Constante para manejar el estado del modal de exportacion de vendedores asignados a productos
  const [showModalExportSellersInProduct, setShowModalExportSellersInProduct] = useState<boolean>(false);

  //Variable con el arreglo para los mails que recibiran notificaciones para edicion
  const [mailsArrayEdit, setMailsArrayEdit] = useState<string[]>(mailsProduct);

  //Variable para el manejo del estado de la informacion de vendedores asignados a productos que se exportara
  const [dataToExportsellersInProduct, setDataToExportsellersInProduct] = useState({})

  //Funcion para manejar el estado de la data que se va a exportar de vendedores asignados al producto
  const openModalExportSellersInProducts = () => {
    setDataToExportsellersInProduct({})
    setShowModalExportSellersInProduct(true)
  }

  //Funcion para cerrar el modal de exportacion de vendedores en productos
  const closeModalExportSellersInProducts = () => {
    setShowModalExportSellersInProduct(false)
  }

  //Variable con estado inicial para el formulario de edicion
  const initialFormEditProduct: FormDataEdit = {
    legalsProduct: '',
    crmIdProduct: '',
    statusProduct: 1,
    nameProduct: '',
    priceProduct: 0,
    typeProduct: '',
    countryProduct: '',
    stateProduct: '',
    cityProduct: '',
    zoneProduct: '',
    gmapsProduct: '',
    wazeProduct: '',
    webProduct: '',
    virtualProduct: '',
    introProduct: '',
    mailsProduct: [],
    logoProduct: '',
    headerProduct: '',
    whaImgProduct: '',
    roleIdProduct: '',
  };

  //Variables para el manejo de estado de la informacion del formulario
  //Se le define 'initialFormEditProduct' como su estado inicial
  const [formDataEdit, setFormDataEdit] = useState<FormDataEdit>(initialFormEditProduct);

  //Variable con estado inicial para el formulario de edicion iscore (Tab lead)
  const initialFormEditLead: FormDataEditLead = {
    leadLlamadaValido: 0,
    leadLlamadaContestada: 0,
    leadLlamadaAceptada: 0,
    leadLlamadaGrabacion: 0,
    leadWhatsInbound: 0,
    leadWhatsHolabot: 0,
    leadWhatsUbicacion: 0,
    leadWhatsHablar: 0,
    leadWhatsWhatsapp: 0,
    leadWhatsWebsite: 0,
    leadWhatsVirtual: 0,
    leadWhatsCita: 0,
    leadWhatsWhattxt: 0,
    leadWhatsCalifica: 0,
    leadWhatsBrochure: 0,
    leadEmailValido: 0,
    leadEmailEntregado: 0,
    leadEmailAbierto: 0,
  }

  //Variables para el manejo de estado de la informacion del formulario de iscore
  //Se le define 'initialFormEditLead' como su estado inicial
  const [formDataEditLead, setFormDataEditLead] = useState<FormDataEditLead>(initialFormEditLead);

  //Variable con estado inicial para el formulario de edicion iscore (Tab seller)
  const initialFormEditSeller: FormDataEditSeller = {
    sellerLlamadaGrabacion: 0,
    sellerWhatsappHablar: 0,
    sellerWhatsappWhatsapp: 0,
    sellerWhatsappHolabot: 0,
    sellerWhatsappCalifica: 0,
    sellerWhatsappEditar: 0,
    sellerWhatsappComentarios: 0,
    sellerWhatsappWhatxt: 0,
    sellerWhatsappIscore: 0,
    sellerEmailAbierto: 0,
  }

  //Variables para el manejo de estado de la informacion del formulario de iscore
  //Se le define 'initialFormEditSeller' como su estado inicial
  const [formDataEditSeller, setFormDataEditSeller] = useState<FormDataEditSeller>(initialFormEditSeller);

  //Funcion para resetear formulario de edicion
  const resetFormEdit = () => {
    setFormDataEdit(initialFormEditProduct);
  };

  //Funcion para resetear formulario de iscore
  const resetFormEditIscore = () => {
    setFormDataEditLead(initialFormEditLead);
    setFormDataEditSeller(initialFormEditSeller);
  }

  //Funcion para abrir modal de edicion de Iscore
  const openModalEditIscore = () => {
    setShowModalEditIscore(true);
  }

  //Funcion para abrir el modal de historial
  const openModalHistory = () => {
    setShowModalHistory(true);
  }

  //Funcion para cerrar el modal de historial
  const closeModalHistory = () => {
    setShowModalHistory(false);
  }

  //Funcion para abrir el modal
  const openModalEdit = () => {
    setShowModalEdit(true);
    /*Aqui seteamos los valores de los inputs del formulario de edicion 
    con los valores de las props de cada registro*/
    setFormDataEdit({
      ...formDataEdit,
      legalsProduct,
      crmIdProduct,
      statusProduct,
      nameProduct,
      priceProduct,
      typeProduct,
      countryProduct,
      stateProduct,
      cityProduct,
      zoneProduct,
      gmapsProduct,
      wazeProduct,
      webProduct,
      virtualProduct,
      introProduct,
      mailsProduct,
      logoProduct,
      headerProduct,
      whaImgProduct,
      roleIdProduct: '',
    })
  };

  //Funcion para cerrar el modal
  const closeModalEdit = () => {
    setShowModalEdit(false);
    resetFormEdit();
  };

  //Funcion para cerrar el modal de edicion de iscore
  const closeModalEditIscore = () => {
    setShowModalEditIscore(false);
    resetFormEditIscore();
  }

  // console.log('interface de productos: ', addressProduct);
  // console.log({addressProduct});
  const TooltipCustom = (props) => <Tooltip {...props}>{intl.formatMessage({ id: 'PRODUCTS.CARD.TOOLTIP_EDIT' })}</Tooltip>
  const TooltipCustom2 = (props) => <Tooltip {...props}>{intl.formatMessage({ id: 'PRODUCTS.CARD.TOOLTIP_ISCORE' })}</Tooltip>
  const TooltipCustom3 = (props) => <Tooltip {...props}>{intl.formatMessage({ id: 'PRODUCTS.CARD.TOOLTIP_CAMPAIGNS' })}</Tooltip>
  const TooltipCustom4 = (props) => <Tooltip {...props}>{intl.formatMessage({ id: 'PRODUCTS.CARD.EXPORT_USERS_ASSIGNED' })}</Tooltip>
  const TooltipCustom5 = (props) => <Tooltip {...props}>{intl.formatMessage({ id: 'LEADS.SPAM_CHANGES_HISTORY' })}</Tooltip>

  return (
    <div className='card border border-2 border-gray-200 border-hover'>
      <div className='card-header border-0 py-7'>
        <div className='card-title m-0'>
          <div className='symbol symbol-50px w-50px bg-light'>
            <img src={BucketMediaURL + logoProduct} alt='CardProducts' className='p-' />
          </div>
        </div>

        <div className='card-toolbar m-0'>
          <div className='d-flex flex-row bd-highlight'>
            <div className='p-1 bd-highlight me-5'>
              {' '}
              <OverlayTrigger placement='top' overlay={TooltipCustom}>
                <button
                  type='button'
                  className='btn btn-clean btn-sm btn-icon btn-active-light-primary me-n3'
                  onClick={openModalEdit}
                >
                  <KTIcon iconName='pencil' className='fs-1' />
                </button>
              </OverlayTrigger>
            </div>
            <div className='p-1 bd-highlight me-5'>
              <OverlayTrigger placement='top' overlay={TooltipCustom2}>
                <button
                  type='button'
                  className='btn btn-clean btn-sm btn-icon btn-active-light-primary me-n3'
                  onClick={openModalEditIscore}
                >
                  <KTIcon iconName='star' className='fs-1' />
                </button>
              </OverlayTrigger>
            </div>

            <div className='p-1 bd-highlight me-5'>
              <OverlayTrigger placement='top' overlay={TooltipCustom4}>
                <button
                  type='button'
                  className='btn btn-clean btn-sm btn-icon btn-active-light-primary me-n3'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={openModalExportSellersInProducts}
                >
                  {/* <KTIcon iconName='user' className='fs-1' /> */}
                  <i className="bi bi-person-fill-down fs-1"></i>
                </button>
              </OverlayTrigger>
            </div>

            <Modal
              show={showModalExportSellersInProduct}
              onHide={closeModalExportSellersInProducts}
              className="modal-sm"
            >
              <Modal.Header closeButton onClick={closeModalExportSellersInProducts}>
                <Modal.Title>
                  <label>Exportar vendedores</label>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <DropdownExportSellersAssignedInProduct dataToExportsellersInProduct={dataToExportsellersInProduct} title={`Vendedores asignados al producto ${nameProduct}`} nameProduct={nameProduct} />
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-sm btn-secondary"
                  type='reset'
                  onClick={() => {
                    closeModalExportSellersInProducts()
                  }}
                >
                  Cerrar
                </button>
              </Modal.Footer>
            </Modal>

            <div className='p-1 bd-highlight me-5'>

              <Link className={
                `nav-link text-active-primary`
              }
                to={`/campaigns?product=${idProduct}`}>
                <OverlayTrigger placement='top' overlay={TooltipCustom3}>
                  <button
                    type='button'
                    className='btn btn-clean btn-sm btn-icon btn-active-light-primary me-n3'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTIcon iconName='category' className='fs-1' />
                  </button>
                </OverlayTrigger>
              </Link>
            </div>

            <div className='p-1 bd-highlight'>
              <OverlayTrigger placement='top' overlay={TooltipCustom5}>
                <button
                  type='button'
                  className='btn btn-clean btn-sm btn-icon btn-active-light-primary me-n3'
                  onClick={openModalHistory}
                >
                  <KTIcon iconName='time' className='fs-1' />
                </button>
              </OverlayTrigger>
            </div>

          </div>

          <Modal
            show={showModalHistory}
            onhide={closeModalHistory}
            scrollable
            className='modal-xl'
          >
            <Modal.Header closeButton onClick={closeModalHistory}>
              <Modal.Title>
                <h1>Historial de cambios</h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ProductsHistoryTable idProduct={idProduct} nameProduct={nameProduct} />
            </Modal.Body>
            <Modal.Footer>
              <button
                className='btn btn-sm btn-secondary'
                type='button'
                onClick={() => {
                  closeModalHistory()
                }}
              >
                Cerrar
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showModalEdit}
            onHide={closeModalEdit}
            scrollable
          >
            <Modal.Header closeButton onClick={closeModalEdit}>
              <Modal.Title>
                <h1>{intl.formatMessage({ id: 'PRODUCTS.CARD.TOOLTIP_EDIT' })}</h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ModalEditProduct
                idProduct={idProduct}
                merchPdfProduct={merchPdfProduct}
                logoProduct={logoProduct}
                headerProduct={headerProduct}
                whaImgProduct={whaImgProduct}
                formDataEdit={formDataEdit}
                setFormDataEdit={setFormDataEdit}
                mailsArrayEdit={mailsArrayEdit}
                setMailsArrayEdit={setMailsArrayEdit}
                // setShowModalEdit={setShowModalEdit}
                // reRenderProducts={reRenderProducts}
                getProductsRegisters={getProductsRegisters}
                closeModalEdit={closeModalEdit}
              />
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-sm btn-secondary"
                type="button"
                onClick={() => {
                  closeModalEdit();
                }}
              >
                {intl.formatMessage({ id: 'PRODUCTS.MODAL_EDIT.RESET_BUTTON' })}
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showModalEditIscore}
            onHide={closeModalEditIscore}
            scrollable
            className="modal-lg"
          >
            <Modal.Header closeButton onClick={closeModalEditIscore}>
              <Modal.Title>
                <h1>{intl.formatMessage({ id: 'PRODUCTS.CARD.TOOLTIP_ISCORE' })}</h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ModalEditIscores
                idProduct={idProduct}
                formDataEditLead={formDataEditLead}
                setFormDataEditLead={setFormDataEditLead}
                formDataEditSeller={formDataEditSeller}
                setFormDataEditSeller={setFormDataEditSeller}
                getProductsRegisters={getProductsRegisters}
                closeModalEditIscore={closeModalEditIscore}
              />
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-sm btn-secondary"
                type="button"
                onClick={() => {
                  closeModalEditIscore();
                }}
              >
                {intl.formatMessage({ id: 'PRODUCTS.MODAL_EDIT_ISCORE.RESET_BUTTON' })}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='card-body p-9'>
        <div className='d-flex mb-1'>
          <h2 className='fs-2 fw-bolder'>
            {nameProduct}
          </h2>
        </div>

        <div className='d-flex mb-4'>
          <h3 className='fs-5 text-muted'>
          {intl.formatMessage({ id: `PRODUCTS.COUNTRY.${countryProduct}` })} - {stateProduct} - {zoneProduct}
          </h3>
        </div>

        <div className='d-flex mb-6'>

          {statusProduct === 1 ? (
            <span className='badge badge-light-success fw-bolder fs-6 px-2 py-1 me-2'>{intl.formatMessage({ id: 'PRODUCTS.CARD.STATUS_ACTIVE' })}</span>
          ) : statusProduct === 0 ? (
            <span className='badge badge-light-danger fw-bolder fs-6 px-2 py-1 me-2'>{intl.formatMessage({ id: 'PRODUCTS.CARD.STATUS_INACTIVE' })}</span>
          ) : (
            <span className='badge badge-light-warning fw-bolder fs-6 px-2 py-1 me-2'>Pendiente</span>
          )}

          <span className='badge badge-light-primary fw-bolder'>{idProduct}</span>

        </div>

        <p className='fs-15 mt-1 mb-7'><span className='fw-bolder'>{intl.formatMessage({ id: 'GLOBAL.EMAIL' })}:</span> <span className='fw-bold text-gray-400'>{parserProduct}</span></p>

        <div>
          <div className='border border-gray-300 border-dashed rounded flex-grow-1 min-w-125px py-3 px-4 mb-3'>
            <p className='fs-6 fw-bolder'>+52556666666</p>
            <div className='fw-bold text-gray-400'>
              {intl.formatMessage({ id: 'PRODUCTS.CARD.ID_CALLS' })}
            </div>
          </div>

          <div className='border border-gray-300 border-dashed rounded flex-grow-1 min-w-125px py-3 px-4 mb-3'>
            <p className='fs-6 fw-bolder'>+52556666666</p>
            <div className='fw-bold text-gray-400'>
              {intl.formatMessage({ id: 'PRODUCTS.CARD.WAB_NUMBER' })}
            </div>
          </div>
        </div>

        <div className='d-flex'>
          <div className='border border-gray-300 border-dashed rounded flex-grow-1 min-w-125px py-3 px-4 me-3 mb-3'>
            <div className='fs-6 fw-bolder'>{dateAddProduct}</div>
            <div className='fw-bold text-gray-400'>
              {intl.formatMessage({ id: 'PRODUCTS.CARD.CREATION_DATE' })}
            </div>
          </div>

          <div className='border border-gray-300 border-dashed rounded flex-grow-1 min-w-125px py-3 px-4 mb-3'>
            <div className='fs-6 fw-bolder'>{campaignsActive}</div>
            <div className='fw-bold text-gray-400'>{intl.formatMessage({ id: 'PRODUCTS.CARD.CAMPAIGNS' })}</div>
          </div>
        </div>

        <div className='mb-5'>
          <div className='border border-gray-300 border-dashed rounded flex-grow-1 min-w-125px py-3 px-4 mb-3'>
            <div className='fs-6 fw-bolder'>100</div>
            <div className='fw-bold text-gray-400'>{intl.formatMessage({ id: 'PRODUCTS.CARD.LEADS_GENERATED' })}</div>
          </div>
        </div>
      </div>

      <div className="card-footer py-6 px-9">
        {merchPdfProduct && (
          <button className="btn btn-clean btn-sm btn-icon btn-active-light-danger me-3" onClick={() => window.open(BucketMediaURL + merchPdfProduct, '_blank', 'noopener noreferrer')}>
            <i className='fas fa-file-pdf fs-2 text-danger'></i> {/* Icono de PDF */}
          </button>
        )}

        {gmapsProduct && (
          <button className="btn btn-clean btn-sm btn-icon btn-active-light-warning me-5" onClick={() => window.open(gmapsProduct, '_blank', 'noopener noreferrer')}>
            <i className='fas fa-map-location-dot fs-2 text-warning'></i> {/* Icono de PDF */}
          </button>
        )}

        {wazeProduct && (
          <button className="btn btn-clean btn-sm btn-icon btn-active-light-primary me-5" onClick={() => window.open(wazeProduct, '_blank', 'noopener noreferrer')}>
            <i className='fab fa-waze fs-2' style={{ color: '#1C8CFF' }}></i> {/* Icono de PDF */}
          </button>
        )}

        {webProduct && (
          <button className="btn btn-clean btn-sm btn-icon btn-active-light-success me-5" onClick={() => window.open(webProduct, '_blank', 'noopener noreferrer')}>
            <i className='bi bi-browser-chrome fs-2 text-success'></i> {/* Icono de PDF */}
          </button>
        )}

        {virtualProduct && (
          <button className="btn btn-clean btn-sm btn-icon btn-active-light-danger me-5" onClick={() => window.open(virtualProduct, '_blank', 'noopener noreferrer')}>
            <i className='fa fa-street-view fs-2' style={{ color: '#EE4A15' }}></i> {/* Icono de PDF */}
          </button>
        )}
      </div>
    </div>
  );
}