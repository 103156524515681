import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { setPageTitle } from '../../utils/functions/_Functions';
import { DragonCem } from '../../utils/constants/_Constants';
import { Modal } from 'react-bootstrap';
import { ShareAccessMainTable } from './tables/ShareAccessMainTable';

const ShareAccess = () => {
    return (
        // <p>Esta es la nueva pagina para share access</p>
        <>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-md-12'>
                        <h1 className='my-2'>Compartir accesos</h1>
                        <div className='card card-custom'>
                            <ShareAccessMainTable />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {ShareAccess}