// Importamos hooks de react que usaremos para este componente
import {FC, useState, useContext} from 'react'
// Importamos componentes Modal, Overlaytrigger y Tooltip de la libreria de bootstrap para react
import {Button, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
// Importamos helpers de metronic
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
// Importamos funcionalidad Link para ruteo de paginas
import {Link} from 'react-router-dom'
// Importamos libreria para manejo de lenguaje
import {useIntl} from 'react-intl'

// ---------------------------------------------------------------------------------------

// Importamos el contexto de usuario
import {UserContext} from '../../../app-context/user-context/UserContext'

// ---------------------------------------------------------------------------------------

// Importamos el componente ModalEditBrand
import {ModalEditBrand} from '../modals/ModalEditBrand'
// Importamos el componente BrandsHistoryTable para el modal de historial de cambios
import { BrandsHistoryTable } from '../tables/BrandsHistoryTable'

// ---------------------------------------------------------------------------------------

// Importamos la funcion brandsEdit para conexion con API de marcas
import {brandsEdit} from '../services/_BrandsEditApi'
// Importamos funcion brandStatusDeactivate para desactivar una marca
import {brandStatusDeactivate} from '../services/_BrandsStatusDeactivateApi'
// Importamos funcion brandStatusActivate para activar una marca
import {brandStatusActivate} from '../services/_BrandsStatusActivateApi'

// ---------------------------------------------------------------------------------------

// Importamos la funcion sessionExpired para el vencimiento de sesion
import {sessionExpired} from '../../../utils/functions/_Functions'

// ---------------------------------------------------------------------------------------

// Importamos las interfaces globales que usaremos para este modulo
import {AlertInfo} from '../../../utils/interfaces/_InterfacesTypes'
// Importamos las interfaces de marcas que usaremos para este componente
import {FormDataEdit} from '../interfaces/brandsTypes'

//Definimos tipado de las propiedades (props)
type Props = {
  idBrand: string
  crmBrand: string
  logoBrand: string
  nameBrand: string
  dateAddBrand: string
  dateUpdateBrand: string
  statusBrand: true | false
  productsBrand: string[]
  productsAssigned: number
  // reRenderBrands: () => void;
  getBrandsRegisters: () => void
}

//Iniciamos funcion para construir componente CardBrands y recibimos las props
export const CardBrands: FC<Props> = ({
  idBrand,
  crmBrand,
  logoBrand,
  nameBrand,
  dateAddBrand,
  dateUpdateBrand,
  statusBrand,
  productsBrand,
  productsAssigned,
  // reRenderBrands,
  getBrandsRegisters,
}) => {

  console.log('renderizacomponente cardbrands')
  //Variable para el uso de diccionario de lenguajes
  const intl = useIntl()

  //Variables desde el usercontext
  const {token} = useContext(UserContext)
  const userToken = token

  //Tooltips para los botones de accion de la tarjeta
  const customTooltip1 = (props) => (
    <Tooltip {...props}>{intl.formatMessage({id: 'BRANDS.CARD.TOOLTIP_EDIT'})}</Tooltip>
  )
  const customTooltip2 = (props) => (
    <Tooltip {...props}>{intl.formatMessage({id: 'BRANDS.CARD.TOOLTIP_PRODUCTS'})}</Tooltip>
  )
  const customTooltip3 = (props) => (
    <Tooltip {...props}>{intl.formatMessage({id: 'BRANDS.CARD.TOOLTIP_HISTORY'})}</Tooltip>
  )

  //Variables para manejo de estado de mostrar o no el modal de edicion
  const [showModalEdit, setShowModalEdit] = useState<boolean>(false)

  //Constante para manejo de estado del modal de historial
  const [showModalHistory, setShowModalHistory] = useState<boolean>(false)

  //Variables para manejo de estado de mostrar el modal de respuesta al desactivar marcas
  const [modalResponseVisible, setModalResponseVisible] = useState(false)

  //Variables para manejo de estado de errores en inputs del formulario
  const [errors, setErrors] = useState<{[key: string]: string}>({})

  const [activeProducts, setActiveProducts] = useState<Product[]>([])

  const [switchOn, setSwitchOn] = useState(statusBrand)

  const [body, setBody] = useState<any>()

  // Define el tipo de los elementos del array jsonData
  type Product = {
    pro_id: string
    name: string
  }

  //Variable con estado inicial para el formulario de edicion
  const initialFormEditBrand: FormDataEdit = {
    idBrand: '',
    nameBrand: '',
    crmBrand: '',
    roleId: '',
    productsBrand: [],
  }

  const [alertInfo, setAlertInfo] = useState<AlertInfo>({
    text: '',
    variant: 'primary',
  })

  //Variables para el manejo de estado de la informacion del formulario
  //Se le define 'initialFormEditBrand' como su estado inicial
  const [formDataEdit, setFormDataEdit] = useState<FormDataEdit>(initialFormEditBrand)

  //Variables para el manejo del array de productos asignados
  //Se define un estado inicial de array numerico vacio
  const [selectedProductIds, setSelectedProductIds] = useState<number[]>([])

  //Variable para el manejo del crm_id proveniente del formulario
  //Se define un estado inicial de string vacio
  // const [crm_id, setCrm_id] = useState<string>('');

  //Funcion para resetear formulario
  const resetFormEdit = () => {
    setFormDataEdit(initialFormEditBrand)
  }

  //Funcion para abrir el modal
  const openModalEdit = () => {
    setShowModalEdit(true)
    /*Aqui seteamos los valores de los inputs del formulario de edicion 
    con los valores de las props de cada registro*/
    setFormDataEdit({
      ...formDataEdit,
      idBrand,
      nameBrand,
      crmBrand,
      roleId: '',
      productsBrand,
    })
  }

  //Funcion para abrir el modal de historial
  const openModalHistory = () => {
    setShowModalHistory(true)
  }

  //Funcion para cerrar el modal de historial
  const closeModalHistory = () => {
    setShowModalHistory(false)
  }

  //Funcion para cerrar el modal
  const closeModalEdit = () => {
    setShowModalEdit(false)
    setErrors({})
    resetFormEdit()
  }

  //Función para cerrar el modal de respuesta al intentar dar de baja una marca
  const closeModal = () => {
    setModalResponseVisible(false)
  }

  //Funcion para el envio del formulario de edicion de marca
  const handleFormEditSubmit = async () => {
    // console.log('inicia tratado de form');

    setErrors({})

    if (!formDataEdit.nameBrand) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        nameBrand: intl.formatMessage({id: 'BRANDS.ERROR_NO_BRAND_NAM'}),
      }))
      return
    }

    //Creamos variables para mandarlas como parametro a la funcion de edicion de la API Brands
    const name_brand: string = formDataEdit.nameBrand
    const brand_id: string = formDataEdit.idBrand
    const crm_id: string = formDataEdit.crmBrand
    const roleId: string = formDataEdit.roleId
    const products: number[] = selectedProductIds

    console.log('nuevo roleId: ', roleId)

    try {
      const {
        data: jsonData,
        code,
        msg,
      } = await brandsEdit(brand_id, name_brand, crm_id, roleId, products, userToken)
      // const userTokenNew = jsonData.token;

      // console.log('actualiza token brandEdit (brands): ', userTokenNew)
      // updateToken(userTokenNew);

      if (code === 401 && msg === 'Token no valido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      } else {
        setAlertInfo({
          text:
            code === 200
              ? intl.formatMessage({id: 'BRANDS.MODAL_EDIT.SUCCESS_BRAND_EDITION'})
              : intl.formatMessage({id: 'BRANDS.MODAL_EDIT.FAIL_BRAND_EDITION'}),
          variant: code === 200 ? 'success' : 'danger',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          closeModalEdit()
          getBrandsRegisters()
        }, 3000)
      }
    } catch (error) {
      // console.error('No se pudo editar la marca', error);
      setAlertInfo({
        text: intl.formatMessage({id: 'GLOBAL.ALERT_ERROR_AXIOS_REQUEST'}),
        variant: 'danger',
      })
    }
  }

  const modalBodyOn = (
    <div className='alert alert-dismissible bg-light-warning border border-warning  mb-2'>
      <div className='d-flex flex-column flex-sm-row p-5'>
        <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='currentColor'
            className='bi bi-exclamation-triangle-fill'
            viewBox='0 0 16 16'
          >
            <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
          </svg>
        </span>
        <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
          <h5 className='mb-1'>{intl.formatMessage({id: 'BRANDS.MODAL.DEACTIVATION_TITLE'})}</h5>
          <span className='text-dark'>
            <p>{intl.formatMessage({id: 'BRANDS.MODAL.DEACTIVATION_CONFIRM'}, {nameBrand})}</p>
            <p>{intl.formatMessage({id: 'BRANDS.MODAL.DEACTIVATION_WARNING'})}</p>
          </span>
        </div>
      </div>
    </div>
  )

  const modalBodyOff = (
    <div className='alert alert-dismissible bg-light-warning border border-warning  mb-2'>
      <div className='d-flex flex-column flex-sm-row p-5'>
        <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0 text-dark'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='16'
            height='16'
            fill='currentColor'
            className='bi bi-exclamation-triangle-fill'
            viewBox='0 0 16 16'
          >
            <path d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5m.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2' />
          </svg>
        </span>
        <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
          <h5 className='mb-1'>{intl.formatMessage({id: 'BRANDS.MODAL.ACTIVATION_TITLE'})}</h5>
          <span className='text-dark'>
            <p>{intl.formatMessage({id: 'BRANDS.MODAL.ACTIVATION_CONFIRM'}, {nameBrand})}</p>
          </span>
        </div>
      </div>
    </div>
  )

  //Esta función solo es para abrir el modal con info del cambio de status de la marca
  const handleCheckboxChange = async (brandId: string) => {
    setModalResponseVisible(true)

    if (statusBrand) {
      setBody(modalBodyOn)
    } else {
      setBody(modalBodyOff)
    }
  }

  //Aqui se debe disparar la funcion que hara el cambio de estatus de la marca
  const handleChangeBrandStatus = async (brandId: string) => {
    try {
      let response
      let petitionType
      if (statusBrand) {
        response = await brandStatusDeactivate(brandId, userToken)
        petitionType = 'brandStatusDeactivate'
      } else {
        response = await brandStatusActivate(brandId, userToken)
        petitionType = 'brandStatusActivate'
      }

      const {data, code, msg} = response

      if (code === 401 && msg === 'Token no válido') {
        sessionExpired(intl.formatMessage({id: 'GLOBAL.ALERT.SESSION_EXPIRED'}))
        return
      }

      if (code === 200 && petitionType === 'brandStatusDeactivate') {
        setAlertInfo({
          text: intl.formatMessage({id: 'BRANDS.ALERT.DEACTIVATION_SUCCESS'}),
          variant: 'success',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          setSwitchOn(false)
          getBrandsRegisters()
          closeModal()
        }, 3000)
        return
      }

      if (code === 200 && petitionType === 'brandStatusActivate') {
        setAlertInfo({
          text: intl.formatMessage({id: 'BRANDS.ALERT.ACTIVATION_SUCCESS'}),
          variant: 'success',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
          setSwitchOn(true)
          getBrandsRegisters()
          closeModal()
        }, 3000)
        return
      }

      if (code === 400 && msg === 'Esta marca tiene productos asignados: ') {
        setSwitchOn(false)
        setAlertInfo({
          text: intl.formatMessage({id: 'BRANDS.ALERT.DEACTIVATION_FAILED_PRODUCTS'}),
          variant: 'warning',
        })

        setTimeout(() => {
          setAlertInfo({
            text: '',
            variant: '',
          })
        }, 3000)
      }
    } catch (error) {
      setAlertInfo({
        text: `${error}`,
        variant: 'danger',
      })
      setTimeout(() => {
        setAlertInfo({
          text: '',
          variant: '',
        })
      }, 3000)
      throw error
    }
  }

  return (
    <div className='card h-100 border-2 border-gray-200 border-hover'>
      <div className='card-header border-0 p-9'>
        
        <div className='card-title m-0'>
          <div className='symbol symbol-45px w-45px bg-light me-5'>
            <img src={toAbsoluteUrl(logoBrand)} alt='CardBrand' className='p-1' />
          </div>
          {/* <h2 className='fs-2 fw-bolder'>{nameBrand}</h2> */}
        </div>

        <div className='card-toolbar m-0'>
          <OverlayTrigger placement='top' overlay={customTooltip1}>
            <button
              type='button'
              className='btn btn-clean btn-lg btn-icon btn-active-light-primary me-n3'
              onClick={openModalEdit}
            >
              <KTIcon iconName='pencil' className='fs-1' />
            </button>
          </OverlayTrigger>

          <Modal 
            show={showModalEdit} 
            onHide={closeModalEdit} 
            scrollable 
            className='modal-default'
          >
            <Modal.Header closeButton onClick={closeModalEdit}>
              <Modal.Title>
                <h1>{intl.formatMessage({id: 'BRANDS.MODAL_EDIT.TITLE'})}</h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ModalEditBrand
                formDataEdit={formDataEdit}
                setFormDataEdit={setFormDataEdit}
                selectedProductIds={selectedProductIds}
                setSelectedProductIds={setSelectedProductIds}
                productsBrand={productsBrand}
                errors={errors}
                setErrors={setErrors}
              />
            </Modal.Body>
            {alertInfo.text && (
              <div className='px-20'>
                <div
                  className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`}
                  role='alert'
                >
                  <div>{alertInfo.text}</div>
                </div>
              </div>
            )}
            <Modal.Footer>
              <button
                className='btn btn-sm btn-secondary'
                type='button'
                onClick={() => {
                  closeModalEdit()
                }}
              >
                {intl.formatMessage({id: 'BRANDS.MODAL_EDIT.RESET_BUTTON'})}
              </button>
              <button
                className='btn btn-sm btn-primary'
                type='submit'
                onClick={handleFormEditSubmit}
              >
                {intl.formatMessage({id: 'BRANDS.MODAL_EDIT.SUBMIT_BUTTON'})}
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={modalResponseVisible}
            onHide={closeModal}
            scrollable
            className='modal-default'
          >
            <Modal.Header closeButton onClick={closeModal}>
              <Modal.Title>{intl.formatMessage({id: 'BRANDS.MODAL.CHANGE_STATUS_TITLE'})}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{body}</Modal.Body>
            {alertInfo.text && (
              <div className='px-20'>
                <div
                  className={`alert alert-${alertInfo.variant} d-flex align-items-center justify-content-center text-center`}
                  role='alert'
                >
                  <div>{alertInfo.text}</div>
                </div>
              </div>
            )}
            <Modal.Footer>
              <Button
                variant='primary'
                onClick={() => {
                  handleChangeBrandStatus(idBrand)
                }}
              >
                {intl.formatMessage({id: 'GLOBAL.INPUT.ACCEPT'})}
              </Button>
              <Button
                variant='secondary'
                onClick={() => {
                  closeModal()
                }}
              >
                {intl.formatMessage({id: 'BRANDS.MODAL_EDIT.RESET_BUTTON'})}
              </Button>
            </Modal.Footer>
          </Modal>

          <Link className={`nav-link text-active-primary me-3`} to={`/products?brand=${idBrand}`}>
            <OverlayTrigger placement='top' overlay={customTooltip2}>
              <button
                type='button'
                className='btn btn-clean btn-lg btn-icon btn-active-light-primary me-n3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='bookmark-2' className='fs-1' />
              </button>
            </OverlayTrigger>
          </Link>

          <OverlayTrigger placement='top' overlay={customTooltip3}>
            <button
              type='button'
              className='btn btn-clean btn-lg btn-icon btn-active-light-primary me-n3'
              onClick={openModalHistory}
            >
              <KTIcon iconName='time' className='fs-1' />
            </button>
          </OverlayTrigger>

          <Modal
            show={showModalHistory} 
            onHide={closeModalHistory} 
            scrollable 
            className='modal-xl'
          >
            <Modal.Header closeButton onClick={closeModalHistory}>
              <Modal.Title>
                <h1>{intl.formatMessage({id: 'BRANDS.MODAL.HISTORY_TITLE'})}</h1>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <h1>hola</h1> */}
                <BrandsHistoryTable brandId={idBrand} brandName={nameBrand}/>
            </Modal.Body>
            <Modal.Footer>
              <button
                className='btn btn-sm btn-secondary'
                type='button'
                onClick={() => {
                  closeModalHistory()
                }}
              >
                {intl.formatMessage({id: 'GLOBAL.CLOSE'})}
              </button>
            </Modal.Footer>
          </Modal>
        </div>      
      </div>

      <div className='separator my-2'></div>

      <div className='card-body d-flex flex-column px-9 pt-6 pb-8'>
        <div className='d-flex mb-1'>
          <h2 className='fs-2 fw-bolder'>
            {nameBrand}
          </h2>
        </div>
        <div className='d-flex mb-6'>
          {statusBrand === true ? (
            <span className='badge badge-light-success fw-bolder fs-6 px-2 py-1 me-2'>
              {intl.formatMessage({id: 'BRANDS.CARD.STATUS_ACTIVE'})}
            </span>
          ) : (
            <span className='badge badge-light-danger fw-bolder fs-6 px-2 py-1 me-2'>
              {intl.formatMessage({id: 'BRANDS.CARD.STATUS_INACTIVE'})}
            </span>
          )}

          <span className='badge badge-light-primary fw-bolder'>{idBrand}</span>
        </div>

        <div className='d-flex mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
            <div className='fs-6 fw-bolder'>{dateAddBrand}</div>
            <div className='fw-bold text-gray-400'>
              {intl.formatMessage({id: 'BRANDS.CARD.CREATION_DATE'})}
            </div>
          </div>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
            <div className='fs-6 fw-bolder'>{productsAssigned}</div>
            <div className='fw-bold text-gray-400'>
              {intl.formatMessage({id: 'BRANDS.CARD.ACTIVE_PRODUCTS'})}
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='card-footer d-flex justify-content-end'>
        <div
          className='form-check form-switch form-switch-sm form-check-custom form-check-solid mb-4'
          key='poo'
        >
          <label className='form-check-label' key={idBrand}>
            {statusBrand === true
              ? intl.formatMessage({id: 'BRANDS.TITLE.FILTER_ACTIVE'})
              : intl.formatMessage({id: 'BRANDS.TITLE.FILTER_UNACTIVE'})}
          </label>
          &nbsp;&nbsp;&nbsp;
          <input
            className='form-check-input'
            type='checkbox'
            name={`brand_${idBrand}`}
            checked={switchOn}
            onChange={() => handleCheckboxChange(idBrand)}
          />
        </div>
      </div>
    </div>
  )
}
