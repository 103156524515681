/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
// import {useAuth} from '../../../../app/modules/auth'
import { Languages } from './Languages'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Modal } from 'react-bootstrap'
import { ModalNewClient } from '../../../../app/standard-modules/clients/modals/ModalNewClient'
import { UserContext } from '../../../../app/app-context/user-context/UserContext'
import { SharedClientsList } from '../../../../app/modules/clients/_SharedClientsListApi'
import { ClientsShared } from '../../../../app/utils/interfaces/_InterfacesTypes'

const HeaderUserMenu: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate();

  // const {currentUser, logout} = useAuth()
  const [showModal, setShowModal] = useState(false)

  const [clientsShared, setClientsShared] = useState<ClientsShared[]>([]);
  const [clientIdSwitchSelected, setClientIdSwitchSelected] = useState<string>('');
  const [clientNameSwitchSelected, setClientNameSwitchSelected] = useState<string>('');

  const abrirSegundoModal = () => {
    setShowModal(true)
  }

  const cerrarSegundoModal = () => {
    setShowModal(false)
  }

  const handleLogout = () => {
    // Comprobar si existe 'miData' en localStorage
    if (localStorage.getItem('miData') !== null) {
      // La clave 'miData' existe y contiene información, así que podemos eliminarla
      localStorage.removeItem('miData')
      console.log('Había información, ya se borró')
      //Actualizar el path 
      localStorage.setItem('actualPath', '')
      window.location.replace("/auth")
    }
  }

  // Obtenemos informacion deñ contexto de usuario
  const { user, token, updateClientInfo, updateToken } = useContext(UserContext);

  console.log('User:', user);
  console.log('Token:', token);
  console.log('updateClientInfo:', updateClientInfo);
  console.log('updateToken:', updateToken);

  const userName = user.usr_data.usr_name
  const clientId = user.cli_id
  const clientName = user.cli_name
  const originalClientId = user.original_cli_id
  const originalClientName = user.original_cli_name
  const userType = user.user_type
  const lvlUser = user.user_type;
  const clients_shared = user.clients_shared;
  const userToken = token
  // console.log(dataFromContext.token);
  // console.log(lvlUser);

  const switchClient = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCliId = e.target.value
    const newCliName = e.target.options[e.target.selectedIndex].text

    setClientIdSwitchSelected(newCliId)
    setClientNameSwitchSelected(newCliName)

    updateClientInfo(newCliId, newCliName)

    window.location.href = '/leads'; // Esto recargará la página
    // navigate('/leads');

  }

  const restoreClient = () => {
    setClientIdSwitchSelected(originalClientId)
    setClientNameSwitchSelected(originalClientName)

    updateClientInfo(originalClientId, originalClientName)

    window.location.href = '/leads'; // Esto recargará la página
    // navigate('/leads');
  }

  useEffect(() => {

    // Mapeamos la lista de clientes compartidos
    const clientsSharedList = clients_shared.map((client: ClientsShared) => {

      return {
        cli_id: client.cli_id,
        cli_name: client.cli_name,
        cli_status: client.cli_status,
        date_add: client.date_add,
      }

    })

    setClientsShared(clientsSharedList);

    console.log(clients_shared)

  }, []);

  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-375px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-4'>
            <div className='symbol symbol-75px me-8'>
              {/* <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-3.jpg')} /> */}
              <img
                alt='Logo'
                src='https://storage.googleapis.com/dragon-bucket-prod/appv2/core/Dragondefault-small.svg'
              />
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex fs-5'>
                {/* {currenktUser?.first_name} {currentUser?.first_name} */}
                {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
              </div>
              <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                {/* {currentUser?.email} */}
              </a>
              <span className='fw-bold text-muted text-hover-primary fs-7'>{userName}</span>
              <span className='fw-bold text-muted text-hover-primary fs-7'>
                {' '}
                {intl.formatMessage({ id: 'MODAL.USER_INFO.CLIENT' })}: {clientName}
              </span>
              <span className='fw-bold text-muted text-hover-primary fs-7'> Rol: {userType}</span>
              <span className='fw-bold text-muted text-hover-primary fs-7'>
                {' '}
                Id cliente: {clientId}{' '}
              </span>
            </div>
          </div>
          <span className='fw-bold text-muted text-hover-primary fs-7 px-4'>
            {intl.formatMessage({ id: 'MODAL.USER_INFO.LAST_SESION' })}:
          </span>
          <p className='fw-bold text-muted text-hover-primary fs-7 px-4'>
            {' '}
            2023 - 08 - 12 | 05:08:18 | Windows 10{' '}
          </p>
        </div>

        <div className='separator my-2'></div>

        <div className='menu-item px-5'>
          {clientId === originalClientId ? (
            <>

              {intl.formatMessage({ id: 'MODAL.USER_INFO.CLIENTS_SHARED' })}
              <select
                name="clients_shared"
                className='form-select form-select-solid mt-1'
                value={clientIdSwitchSelected}
                onChange={(e) => {
                  switchClient(e)
                }}
              >
                <option value=''>{intl.formatMessage({ id: 'MODAL.USER_INFO.DROPDOWN_OPTION_ONE' })}</option>
                {clientsShared.map(client => (
                  <option key={client.cli_id} value={client.cli_id}>{client.cli_name}</option>
                ))}
              </select>
            </>
          ) : (
            <>
              <button
                className='btn btn-sm btn-success'
                type='button'
                // data-bs-toggle='modal'
                // data-bs-target='#kt_modal_new_client'
                onClick={() => {
                  restoreClient()
                }}
              >
                Regresar a {originalClientName}
              </button>
            </>
          )}
        </div>

        {lvlUser === 'DEVELOPER' || lvlUser === 'SUPER_ADMIN' ? (
          <div className='px-5 mt-3 mb-1'>
            <button
              className='btn btn-sm btn-primary'
              type='button'
              // data-bs-toggle='modal'
              // data-bs-target='#kt_modal_new_client'
              onClick={abrirSegundoModal}
            >
              {intl.formatMessage({ id: 'MODAL.USER_INFO.BUTTON_NEW_CLIENT' })}
            </button>
          </div>
        ) : null}

        <div className='separator my-2'></div>

        <Languages />

        <div className='menu-item px-5'>
          <Link to='/auth' className='menu-link px-5' onClick={handleLogout}>
            {intl.formatMessage({ id: 'MODAL.USER_INFO.LOGOUT' })}
          </Link>
        </div>
      </div>

      <Modal show={showModal} onHide={cerrarSegundoModal} centered scrollable className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Nuevo Cliente</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalNewClient onClose={cerrarSegundoModal} />
        </Modal.Body>
        <Modal.Footer>
          <button
            className='btn btn-sm btn-secondary'
            type='button'
            onClick={cerrarSegundoModal} // Cierra el segundo modal
          >
            Cancelar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export { HeaderUserMenu }
